<template>
  <div>
    <div>
      <div
        v-if="!displayCharts"
        class="col row text-subtitle1"
        :class="displayOverfed && 'q-py-md'"
      >
        <div
          class="col-7 cursor-pointer"
          style="padding: 20px 0 0 0"
          @click="dialogInfo = true"
        >
          <div class="row">
            <div class="col q-px-sm">
              <span
                style="border-bottom: 1px solid black"
                :class="displayCharts && 'text-h6'"
              >
                {{ pond.name }}
              </span>
            </div>
            <div class="col-7 row">
              <div
                class="col-6 q-pl-sm"
                :class="LastFeedingSkipped && 'text-accent'"
                :style="LastFeedingSkipped && 'opacity: 0.6'"
              >
                {{ numberWithCommas(LastFeeding.pounds) }}
              </div>

              <div v-if="CurrentFeeding.pounds !== null" class="col-6 q-pl-sm">
                {{ numberWithCommas(CurrentFeeding.pounds) }}
              </div>

              <div v-else class="col-6 text-grey-5 q-pl-sm">
                <div>{{ PondTFA }}</div>
              </div>
            </div>
          </div>

          <div class="row" style="height: 20px">
            <div class="col"></div>
            <div class="col-7 row">
              <div class="col-6 row q-col-gutter-x-xs q-px-sm">
                <div v-if="LastFeeding.overfed === 0" class="col-4">
                  <div
                    class="bg-secondary"
                    style="height: 8px; border-radius: 3px"
                  ></div>
                </div>

                <div v-else class="col-4">
                  <div
                    :class="LastFeeding.overfed > 1 ? 'bg-accent' : ''"
                    style="height: 8px; border-radius: 3px"
                  ></div>
                </div>
                <div class="col-4">
                  <div
                    :class="
                      LastFeeding.overfed > 2
                        ? 'bg-accent'
                        : LastFeeding.overfed > 1
                        ? 'bg-grey-4'
                        : ''
                    "
                    style="height: 8px; border-radius: 3px"
                  ></div>
                </div>
                <div class="col-4">
                  <div
                    :class="
                      LastFeeding.overfed > 3
                        ? 'bg-accent'
                        : LastFeeding.overfed > 1
                        ? 'bg-grey-4'
                        : ''
                    "
                    style="height: 8px; border-radius: 3px"
                  ></div>
                </div>
              </div>

              <div
                class="col-6 row q-col-gutter-x-xs"
                style="padding-left: 12px"
              >
                <div v-if="CurrentFeeding.overfed === 0" class="col-4">
                  <div
                    class="bg-secondary"
                    style="height: 8px; border-radius: 3px"
                  ></div>
                </div>
                <div v-else class="col-4">
                  <div
                    :class="CurrentFeeding.overfed > 1 ? 'bg-accent' : ''"
                    style="height: 8px; border-radius: 3px"
                  ></div>
                </div>
                <div class="col-4">
                  <div
                    :class="
                      CurrentFeeding.overfed > 2
                        ? 'bg-accent'
                        : CurrentFeeding.overfed > 1
                        ? 'bg-grey-4'
                        : ''
                    "
                    style="height: 8px; border-radius: 3px"
                  ></div>
                </div>
                <div class="col-4">
                  <div
                    :class="
                      CurrentFeeding.overfed > 3
                        ? 'bg-accent'
                        : CurrentFeeding.overfed > 1
                        ? 'bg-grey-4'
                        : ''
                    "
                    style="height: 8px; border-radius: 3px"
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-if="displayOverfed" class="col">
          <q-card class="q-py-sm q-px-md">
            <div class="text-center q-pb-xs">
              {{ SliderLabel }}
            </div>

            <q-slider
              v-model="overfedSlider"
              :disable="CurrentFeeding.pounds === null"
              :min="0"
              :max="4"
              markers
              :step="1"
              snap
              :color="SliderColor"
              track-size="8px"
              thumb-size="24px"
              @input="submitOverfedDebouncer"
            />
          </q-card>
        </div>

        <div v-if="!displayOverfed" class="col row items-center">
          <q-btn
            unelevated
            color="grey-4"
            textColor="black"
            padding="10px 0px"
            class="full-width"
            size="md"
            @click="feedPond(pond)"
            @click.stop
          >
            <div class="row items-center justify-around full-width">
              <div style="width: 30px"></div>
              <div>Feed</div>
              <div
                v-if="CurrentFeeding.pounds !== null"
                style="width: 30px; height: 24px"
              >
                <q-icon name="task_alt" color="primary" />
              </div>
              <div v-else style="width: 30px; height: 24px"></div>
            </div>
          </q-btn>
        </div>
      </div>

      <div v-if="displayCharts" class="q-py-xs">
        <q-card :id="`chart-container-parent-${pond.id}`">
          <div class="row items-center q-px-md">
            <div
              class="col-7 row items-center cursor-pointer q-pb-xs"
              @click="dialogInfo = true"
            >
              <div class="col q-pr-sm">
                <span
                  style="border-bottom: 1px solid black"
                  :class="displayCharts && 'text-h6'"
                >
                  {{ pond.name }}
                </span>
              </div>
            </div>

            <div v-if="!displayOverfed" class="col q-py-md">
              <q-btn
                unelevated
                color="grey-4"
                textColor="black"
                padding="10px 0px"
                class="full-width"
                size="md"
                @click="feedPond(pond)"
                @click.stop
              >
                <div
                  class="row items-center justify-between full-width q-px-md"
                >
                  <div style="width: 24px"></div>
                  <div>Feed</div>
                  <div v-if="CurrentFeeding.pounds !== null">
                    <q-icon name="task_alt" color="primary" />
                  </div>
                  <div v-else style="width: 24px"></div>
                </div>
              </q-btn>
            </div>

            <div v-if="displayOverfed" class="col q-py-md">
              <q-card outlined class="q-py-sm q-px-md">
                <div class="text-center q-pb-xs">
                  {{ SliderLabel }}
                </div>

                <q-slider
                  v-model="overfedSlider"
                  :disable="CurrentFeeding.pounds === null"
                  :min="0"
                  :max="4"
                  markers
                  marker-labels
                  :step="1"
                  snap
                  :color="SliderColor"
                  track-size="8px"
                  thumb-size="24px"
                  @input="submitOverfedDebouncer"
                />
              </q-card>
            </div>
          </div>
          <q-separator class="full-width" />

          <div class="row text-subtitle1 q-px-md" style="padding-top: 12px">
            <div class="col-6">
              Last <span> - </span> {{ LastFeeding.pounds }}
            </div>
            <div v-if="CurrentFeeding.pounds !== null" class="col-6">
              <span v-if="feedDividerOption === 1"> Current </span>
              <span v-if="feedDividerOption === 0"> Today </span>
              <span> - </span> {{ CurrentFeeding.pounds }}
            </div>

            <div v-else class="col-6 text-grey-6 q-pl-sm">
              <div v-if="PondTFADisplay">TFA - {{ PondTFA }}</div>
            </div>

            <div class="col-12 row" style="height: 12px">
              <div class="col-6 row">
                <div class="col-6 col-sm-4 row q-col-gutter-x-xs">
                  <div v-if="LastFeeding.overfed === 0" class="col-4">
                    <div
                      class="bg-secondary"
                      style="height: 8px; border-radius: 3px"
                    ></div>
                  </div>

                  <div v-else class="col-4">
                    <div
                      :class="LastFeeding.overfed > 1 ? 'bg-accent' : ''"
                      style="height: 8px; border-radius: 3px"
                    ></div>
                  </div>

                  <div class="col-4">
                    <div
                      :class="
                        LastFeeding.overfed > 2
                          ? 'bg-accent'
                          : LastFeeding.overfed > 1
                          ? 'bg-grey-4'
                          : ''
                      "
                      style="height: 8px; border-radius: 3px"
                    ></div>
                  </div>
                  <div class="col-4">
                    <div
                      :class="
                        LastFeeding.overfed > 3
                          ? 'bg-accent'
                          : LastFeeding.overfed > 1
                          ? 'bg-grey-4'
                          : ''
                      "
                      style="height: 8px; border-radius: 3px"
                    ></div>
                  </div>
                </div>
              </div>

              <div class="col-6 row">
                <div class="col-6 col-sm-4 row q-col-gutter-x-xs">
                  <div v-if="CurrentFeeding.overfed === 0" class="col-4">
                    <div
                      class="bg-secondary"
                      style="height: 8px; border-radius: 3px"
                    ></div>
                  </div>

                  <div v-else class="col-4">
                    <div
                      :class="CurrentFeeding.overfed > 1 ? 'bg-accent' : ''"
                      style="height: 8px; border-radius: 3px"
                    ></div>
                  </div>
                  <div class="col-4">
                    <div
                      :class="
                        CurrentFeeding.overfed > 2
                          ? 'bg-accent'
                          : CurrentFeeding.overfed > 1
                          ? 'bg-grey-4'
                          : ''
                      "
                      style="height: 8px; border-radius: 3px"
                    ></div>
                  </div>
                  <div class="col-4">
                    <div
                      :class="
                        CurrentFeeding.overfed > 3
                          ? 'bg-accent'
                          : CurrentFeeding.overfed > 1
                          ? 'bg-grey-4'
                          : ''
                      "
                      style="height: 8px; border-radius: 3px"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <q-separator class="full-width" />

          <div
            :id="`chart-container-${pond.id}`"
            style="height: 160px"
            class="q-pa-xs"
          >
            <FeedChart
              :chartData="pond.chartData"
              :chartId="pond.id.toString()"
            />
          </div>
        </q-card>
      </div>
    </div>

    <!-- Info Dialog -->
    <q-dialog position="right" full-hight v-model="dialogInfo">
      <q-card style="width: 300px">
        <div class="row items-center justify-between q-pa-xs">
          <div style="width: 44px"></div>
          <div class="col text-h6 text-center">
            {{ pond.name }}
          </div>
          <div>
            <q-btn
              unelevated
              icon="close"
              color="grey-3"
              text-color="black"
              size="md"
              padding="2px 10px"
              v-close-popup
            />
          </div>
        </div>
        <q-separator />

        <div v-if="PondTFADisplay" class="text-center q-py-xs">
          TFA
          <span class="q-px-sm"> - </span>
          {{ numberWithCommas(pond.growth?.targetFeedAmount ?? 0) }} lbs
        </div>
        <q-separator v-if="PondTFADisplay" />

        <div class="row text-subtitle2">
          <div class="col-auto text-center" style="width: 70px">Date</div>
          <div class="col text-center">Pounds</div>
          <div class="col-auto text-center" style="width: 44px">Note</div>
          <div class="col-auto" style="width: 52px"></div>
        </div>
        <q-separator />

        <div v-for="(feeding, i) in LastTenEntries" :key="feeding.id">
          <div class="row text-body2 q-px-xs">
            <div
              class="col-auto q-pl-xs"
              style="width: 70px; padding: 12px 0 12px 0"
            >
              {{ parseTimeStamp(feeding.date, 'short') }}
            </div>

            <div class="col text-center" style="padding-top: 12px">
              {{ numberWithCommas(feeding.pounds) }}

              <div class="row justify-center">
                <div class="col-6 row q-col-gutter-x-xs q-pl-xs">
                  <div v-if="feeding.over_fed === 0" class="col-12">
                    <div
                      class="bg-secondary"
                      style="height: 6px; border-radius: 3px"
                    ></div>
                  </div>

                  <div v-else class="col-4">
                    <div
                      :class="feeding.over_fed > 1 ? 'bg-accent' : ''"
                      style="height: 6px; border-radius: 3px"
                    ></div>
                  </div>
                  <div class="col-4">
                    <div
                      :class="
                        feeding.over_fed > 2
                          ? 'bg-accent'
                          : feeding.over_fed > 1
                          ? 'bg-grey-4'
                          : ''
                      "
                      style="height: 6px; border-radius: 3px"
                    ></div>
                  </div>
                  <div class="col-4">
                    <div
                      :class="
                        feeding.over_fed > 3
                          ? 'bg-accent'
                          : feeding.over_fed > 1
                          ? 'bg-grey-4'
                          : ''
                      "
                      style="height: 6px; border-radius: 3px"
                    ></div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-auto row items-center q-px-sm" style="width: 56px">
              <q-btn
                v-if="feeding.memo"
                unelevated
                icon="sticky_note_2"
                color="grey-3"
                text-color="black"
                size="md"
                padding="2px 10px"
                @click="displayNote(feeding)"
              />
            </div>
            <div class="col-auto row items-center">
              <q-btn
                unelevated
                icon="edit"
                color="grey-3"
                text-color="accent"
                padding="2px 10px"
                @click="editFeeding(feeding)"
              />
            </div>
          </div>
          <q-separator v-if="i < 9" color="grey-2" />
        </div>
      </q-card>
    </q-dialog>

    <!-- Notes Dialog -->
    <q-dialog position="right" full-hight v-model="dialogNote">
      <q-card style="width: 300px">
        <div class="row items-center q-pa-sm">
          <div class="col">
            <div class="text-center text-subtitle1">
              {{ parseTimeStamp(feeding?.date || '', 'short') }}
            </div>
          </div>
          <div>
            <q-btn
              unelevated
              icon="close"
              color="grey-3"
              text-color="black"
              size="md"
              padding="2px 10px"
              v-close-popup
            />
          </div>
        </div>
        <q-separator color="black" style="padding-top 1px" />

        <div class="q-pa-sm">
          <span class="text-subtitle2 q-pr-sm"> Note: </span>
          {{ feeding?.memo ?? '' }}
        </div>
      </q-card>
    </q-dialog>

    <!-- Feed Pond Dialog -->
    <q-dialog persistent full-height position="top" v-model="dialogFeedPond">
      <q-card style="width: 450px">
        <div class="scroll" style="max-height: calc(100vh - 50px)">
          <div class="text-h6 text-center q-py-sm">
            {{ pond.name }}
          </div>
          <q-separator />

          <q-form @submit="submitEntry(true)">
            <div class="q-px-lg q-pt-lg q-pb-sm">
              <div class="q-pb-sm">
                <q-input
                  outlined
                  dense
                  label="Date"
                  v-model="transaction.date"
                  lazy-rules
                  :rules="[(val) => isDateValid(val) || 'Invalid Date']"
                  hide-bottom-space
                >
                  <template v-slot:append>
                    <q-icon name="event" class="cursor-pointer" color="primary">
                      <q-popup-proxy
                        ref="qDateProxy"
                        transition-show="scale"
                        transition-hide="scale"
                      >
                        <q-date v-model="transaction.date" mask="MM/DD/YYYY">
                          <div class="row items-center justify-end">
                            <q-btn
                              v-close-popup
                              label="Close"
                              color="primary"
                              flat
                            />
                          </div>
                        </q-date>
                      </q-popup-proxy>
                    </q-icon>
                  </template>
                </q-input>
              </div>

              <div>
                <q-input
                  outlined
                  dense
                  autogrow
                  v-model="transaction.memo"
                  label="Note"
                />
              </div>

              <div class="q-pt-xs">
                <div style="padding: 4px 12px 0 12px">
                  <div class="text-center q-pb-xs">
                    {{ SliderDialogLabel }}
                  </div>

                  <q-slider
                    dense
                    v-model="overfedDialogSlider"
                    :min="0"
                    :max="4"
                    markers
                    :step="1"
                    snap
                    :color="SliderDialogColor"
                    track-size="10px"
                    thumb-size="30px"
                  />
                </div>
                <div class="row justify-center text-caption q-pb-xs">
                  Feed Eval
                </div>
              </div>
            </div>
            <q-separator />

            <div class="q-pa-md">
              <div class="q-px-sm q-pb-sm">
                <div class="text-center text-subtitle2">Enter Lbs</div>
                <div
                  class="row items-center text-h6"
                  style="
                    height: 50px;
                    padding: 0px 20px;
                    border: 1px solid black;
                    border-radius: 30px;
                  "
                >
                  <span v-if="PoundsDisplay !== null">
                    {{ PoundsDisplay }}
                  </span>
                  <span
                    v-if="PondTFADisplay && !PoundsDisplay"
                    class="text-grey-5"
                  >
                    TFA - {{ PondTFA }}
                  </span>
                </div>

                <div
                  v-if="poundsErr"
                  class="text-accent"
                  style="padding: 0px 22px"
                >
                  Please enter a value
                </div>
              </div>

              <div class="row">
                <div class="col-4" v-for="n in 9" :key="n">
                  <div class="q-pa-sm">
                    <q-btn
                      rounded
                      color="primary"
                      class="full-width"
                      padding="10px"
                      @click="addToPounds(n)"
                    >
                      <div class="text-h6">
                        {{ n }}
                      </div>
                    </q-btn>
                  </div>
                </div>

                <div class="col-8 q-pa-sm">
                  <q-btn
                    rounded
                    color="primary"
                    class="full-width"
                    padding="10px"
                    @click="addToPounds(0)"
                  >
                    <div class="text-h6">0</div>
                  </q-btn>
                </div>

                <div class="col-4 q-pa-sm">
                  <q-btn
                    rounded
                    color="primary"
                    class="full-width"
                    padding="10px"
                    @click="backspace"
                  >
                    <div style="padding: 2px 4px 2px 0">
                      <q-icon name="backspace" size="28px" />
                    </div>
                  </q-btn>
                </div>
              </div>
            </div>
            <q-separator />

            <div class="row q-py-lg q-px-lg">
              <div class="col q-pr-sm">
                <q-btn
                  rounded
                  label="Cancel"
                  color="grey-4"
                  text-color="black"
                  size="md"
                  padding="10px md"
                  class="full-width"
                  v-close-popup
                />
              </div>
              <div v-if="action === 'edit'" class="col q-px-sm">
                <q-btn
                  rounded
                  label="Delete"
                  color="grey-4"
                  text-color="accent"
                  size="md"
                  padding="10px md"
                  class="full-width"
                  @click="deleteFeeding"
                />
              </div>
              <div class="col q-pl-sm">
                <q-btn
                  rounded
                  :label="`Submit ${action === 'edit' ? '' : 'Feed'}`"
                  color="secondary"
                  text-color="black"
                  size="md"
                  padding="10px md"
                  class="full-width"
                  type="submit"
                />
              </div>
            </div>
          </q-form>
        </div>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import FeedChart from '@/components/feed/FeedChart.vue';

import {
  decoder,
  numberWithCommas,
  isNumValid,
  cloneObj,
  getPercentage,
  getScrollbarWidth
} from '@/lib/helpers';
import {
  createTransactionObj,
  getUID,
  guidMatchHelper
} from '@/store/transactionHelpers';
import { parseTimeStamp, isDateValid, setTimeOfDay } from '@/lib/date-utils.js';
import { date } from 'quasar';
import store from '@/store';
import {
  getLastByDay,
  getLastByFeeding,
  getCurrentByDay,
  getCurrentByFeeding
} from '@/lib/feedingHelpers';

export default {
  name: 'FeedPondRow',
  components: {
    FeedChart
  },
  props: {
    FeederSelected: {
      type: Object,
      required: true
    },
    pond: {
      type: Object,
      required: true
    },
    displayCharts: {
      type: Boolean,
      required: true
    },
    displayOverfed: {
      type: Boolean,
      required: true
    },
    lastFeedingDates: {
      type: Object,
      required: true
    },
    resizeWatcher: {
      type: Boolean,
      required: true
    },
    feedDividerOption: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      action: 'create',
      dateCopy: null,
      dateCopyStr: null,
      dialogFeedPond: false,
      dialogInfo: false,
      dialogNote: false,
      isDateValid,
      feeding: null,
      numberWithCommas,
      overfedSlider: 1,
      overfedDialogSlider: 1,
      parseTimeStamp,
      poundsCopy: null,
      poundsErr: false,
      transaction: createTransactionObj()
    };
  },
  mounted() {
    this.setOverfedSlider();
    this.onResize();

    const feedingDate = this.LastFeeding.overfedFeeding?.date;
    this.$emit('setLastFeedingDate', this.pond.id, feedingDate);
  },
  methods: {
    addToPounds(n) {
      this.poundsErr = false;

      if (this.transaction.pounds === null) {
        this.transaction.pounds = '0';
      }

      if (parseInt(this.transaction.pounds) > 99999) {
        return;
      }

      if (this.transaction.pounds.length > 1 && n === 0) {
        this.transaction.pounds += n.toString();
        return;
      }

      this.transaction.pounds = parseFloat(
        this.transaction.pounds + n
      ).toString();
    },
    backspace() {
      if (!this.transaction.pounds) {
        return;
      }

      if (this.transaction.pounds.length === 1) {
        this.transaction.pounds = null;
        return;
      }

      this.transaction.pounds = this.transaction.pounds.slice(0, -1);
    },
    deleteFeeding() {
      this.$q
        .dialog({
          title: 'Confirm',
          message: 'Are you sure you want to delete this entry?',
          ok: {
            color: 'accent',
            label: 'Delete',
            icon: 'delete'
          },
          cancel: {
            label: 'Cancel',
            color: 'grey-2',
            textColor: 'primary'
          },
          persistent: true,
          focus: 'none'
        })
        .onOk(() => {
          this.deleteFeedingConfirmed();
        });
    },
    deleteFeedingConfirmed() {
      const transaction = {
        ...cloneObj(this.transaction),
        storeInfo: { farmId: this.$store.state.farm.id }
      };

      transaction.storeInfo.action = 'deletePondEntry';
      transaction.storeInfo.category = 'pondEntries';
      transaction.storeInfo.pondId = this.pond.id;
      transaction.storeInfo.pondGrainId = this.pond.grain_id;

      const growth = cloneObj(this.pond.growth);
      growth.CurrentUnharvestedFeed =
        parseFloat(growth.CurrentUnharvestedFeed) - transaction.pounds;
      transaction.storeInfo.growth = growth;

      guidMatchHelper(transaction, 'delete');

      // Add details to update feeder info
      const feeder = this.FeederOptions.find(
        (x) => x.value === transaction.from_account
      );

      transaction.storeInfo.feederId = feeder.id;
      transaction.storeInfo.feederGrainId = feeder.value;
      transaction.storeInfo.feederBal =
        parseFloat(feeder.balance) - transaction.pounds;

      store.dispatch('publish', transaction);

      this.$q.notify({
        message: 'Entry Deleted',
        color: 'accent',
        icon: 'delete'
      });

      this.dialogFeedPond = false;
    },
    displayNote(feeding) {
      this.feeding = feeding;
      this.dialogNote = true;
    },
    editFeeding(feeding) {
      this.action = 'edit';
      this.transaction = {
        ...createTransactionObj(),
        ...cloneObj(feeding)
      };

      this.dateCopy = this.transaction.date;
      this.transaction.date = date.formatDate(
        this.transaction.date * 1000,
        'MM/DD/YYYY'
      );
      this.dateCopyStr = this.transaction.date;
      this.poundsCopy = parseFloat(this.transaction.pounds);
      this.transaction.pounds = this.transaction.pounds.toString();
      this.overfedDialogSlider = this.transaction.over_fed;

      this.dialogFeedPond = true;
    },
    feedPond(pond) {
      this.action = 'create';
      this.transaction = createTransactionObj();
      this.transaction.pounds = null;
      this.transaction.date = date.formatDate(new Date(), 'MM/DD/YYYY');
      this.poundsErr = false;
      this.overfedDialogSlider = 1;

      this.dialogFeedPond = true;
    },
    onResize() {
      const pondsContainer = document.getElementById('ponds-container');

      const width =
        pondsContainer.clientWidth > 600
          ? 600
          : pondsContainer.clientWidth - 36;

      const chartContainer = document.getElementById(
        `chart-container-${this.pond.id}`
      );

      if (chartContainer) {
        chartContainer.style.width = `${width}px`;
      }
    },
    setOverfedSlider() {
      this.overfedSlider = this.CurrentFeeding.overfedFeeding?.over_fed ?? 1;
    },
    submitEntry(notify) {
      if (this.transaction.pounds !== '0' && !this.transaction.pounds) {
        this.poundsErr = true;
        return;
      }
      this.poundsErr = false;

      const transaction = cloneObj(this.transaction);

      transaction.purchaser = this.pond.purchaser;

      const dateStr = this.transaction.date;
      transaction.date = setTimeOfDay(dateStr);

      if (this.action === 'edit' && this.dateCopyStr === dateStr) {
        transaction.date = this.dateCopy;
      }

      if (this.action === 'create') {
        transaction.id = 0;
      }

      transaction.to_account = this.pond.grain_id;
      transaction.from_account = this.FeederSelected.grain_id;
      transaction.pounds = parseFloat(transaction.pounds);
      transaction.over_fed = this.overfedDialogSlider;

      this.submitEntryHelper(transaction);
      store.dispatch('publish', transaction);

      this.dialogFeedPond = false;

      if (notify) {
        this.$q.notify({
          message: `Entry ${this.action === 'create' ? 'Added' : 'Updated'}`,
          icon: 'check',
          color: 'primary'
        });
      }
    },
    submitEntryHelper(transaction) {
      // This helper adds information necessary for the submit process
      // but is not part of the transaction table - (mutates transaction)
      // Some of this information injected here is normally provided by
      // left joins on the server, but we push this update to state
      // immediately so we can accomodate offline entries

      transaction.storeInfo.action =
        this.action === 'create' ? 'createPondEntry' : 'updatePondEntry';

      const growth = cloneObj(this.pond.growth);
      growth.CurrentUnharvestedFeed =
        parseFloat(growth.CurrentUnharvestedFeed) +
        (transaction.pounds - this.poundsCopy);
      transaction.storeInfo.growth = growth;

      transaction.storeInfo.category = 'pondEntries';
      transaction.storeInfo.pondId = this.pond.id;
      transaction.storeInfo.pondGrainId = this.pond.grain_id;

      guidMatchHelper(transaction, this.action);

      if (this.action === 'create') {
        transaction.feeder_name = this.FeederSelected.name;
        transaction.feed_type = this.FeederSelected.feedTypeId;
        transaction.feed_type_name = this.FeederSelected.feedTypeName;
      }

      const feeder = this.FeederOptions.find(
        (x) => x.value === transaction.from_account
      );

      // Add details to update feeder info
      transaction.storeInfo.feederId = feeder.id;
      transaction.storeInfo.feederGrainId = feeder.value;
      transaction.storeInfo.feederBal =
        parseFloat(feeder.balance) + (this.poundsCopy - transaction.pounds);
      transaction.pond_name = this.pond.name;
    },
    submitOverfed() {
      this.action = 'edit';
      this.transaction = {
        ...createTransactionObj(),
        ...cloneObj(this.CurrentFeeding.overfedFeeding)
      };

      this.dateCopy = this.transaction.date;
      this.transaction.date = date.formatDate(
        this.transaction.date * 1000,
        'MM/DD/YYYY'
      );
      this.dateCopyStr = this.transaction.date;
      this.poundsCopy = parseFloat(this.transaction.pounds);
      this.transaction.pounds = this.transaction.pounds.toString();
      this.overfedDialogSlider = this.overfedSlider;

      this.submitEntry(false);
    },
    submitOverfedDebouncer() {
      clearTimeout(this.submitOverfedTimeout);

      this.submitOverfedTimeout = setTimeout(() => {
        this.submitOverfed();
      }, 500);
    }
  },
  computed: {
    CurrentFeeding() {
      if (this.feedDividerOption === 0) {
        return getCurrentByDay(this.pond.feedings);
      } else {
        return getCurrentByFeeding(this.pond.feedings);
      }
    },
    FeederOptions() {
      const options = [];
      for (const feeder of store.state.farm.feeders) {
        // get the feed type of the feeder
        let feedTypeId = null;
        let feedTypeName = '---';
        for (const entry of feeder.fillUps) {
          if (entry.feed_type) {
            feedTypeId = entry.feed_type;
            feedTypeName = entry.feed_type_name;
            break;
          }
        }

        options.push({
          id: feeder.id,
          balance: feeder.balance,
          label: feeder.name,
          value: feeder.grain_id,
          feedTypeId,
          feedTypeName
        });
      }

      return options;
    },
    LastFeeding() {
      if (this.feedDividerOption === 0) {
        return getLastByDay(this.pond.feedings);
      } else {
        return getLastByFeeding(this.pond.feedings);
      }
    },
    LastFeedingSkipped() {
      let lastFeedingDate = this.lastFeedingDates[this.pond.id] * 1000;
      lastFeedingDate = +new Date(
        date.startOfDate(new Date(lastFeedingDate), 'day')
      );

      for (const feedingDate of Object.values(this.lastFeedingDates)) {
        let feedingDateStart = feedingDate * 1000;
        feedingDateStart = +new Date(
          date.startOfDate(new Date(feedingDateStart), 'day')
        );

        if (feedingDateStart > lastFeedingDate) {
          return true;
        }
      }

      return false;
    },
    LastTenEntries() {
      const lastTen = [];
      for (const feeding of this.pond.feedings) {
        // This is a fish loss entry - skip it
        // if (!feeding.feeder_name && feeding.to_account !== this.pond.grain_id) {
        //   continue;
        // }

        if (lastTen.length === 10) {
          break;
        }

        lastTen.push(feeding);
      }

      return lastTen;
    },
    PondTFA() {
      if (!this.PondTFADisplay) {
        return '';
      }

      return this.pond.growth?.targetFeedAmount ?? 0 > 0
        ? numberWithCommas(this.pond.growth?.targetFeedAmount ?? 0)
        : '0';
    },
    PondTFADisplay() {
      return store.state.farm?.settings?.display_tfa ?? true;
    },
    PoundsDisplay() {
      if (this.transaction.pounds === null) {
        return null;
      }

      // if (this.transaction.pounds === '0') {
      //   return '0';
      // }

      return numberWithCommas(parseFloat(this.transaction.pounds));
    },
    SliderColor() {
      return this.overfedSlider === 0
        ? 'secondary'
        : this.overfedSlider === 1
        ? 'primary'
        : 'accent';
    },
    SliderLabel() {
      return this.overfedSlider === 0
        ? 'Satiated'
        : this.overfedSlider === 1
        ? 'No Eval'
        : this.overfedSlider === 2
        ? 'Overfed 1'
        : this.overfedSlider === 3
        ? 'Overfed 2'
        : 'Overfed 3';
    },
    SliderDialogColor() {
      return this.overfedDialogSlider === 0
        ? 'secondary'
        : this.overfedDialogSlider === 1
        ? 'primary'
        : 'accent';
    },
    SliderDialogLabel() {
      return this.overfedDialogSlider === 0
        ? 'Satiated'
        : this.overfedDialogSlider === 1
        ? 'No Eval'
        : this.overfedDialogSlider === 2
        ? 'Overfed 1'
        : this.overfedDialogSlider === 3
        ? 'Overfed 2'
        : 'Overfed 3';
    }
  },
  watch: {
    LastFeeding() {
      const feedingDate = this.LastFeeding.overfedFeeding?.date;
      this.$emit('setLastFeedingDate', this.pond.id, feedingDate);
    },
    resizeWatcher() {
      this.onResize();
    },
    pond() {
      this.setOverfedSlider();
    }
  }
};
</script>

<style></style>
