<template>
  <div>
    <div id="adjustable">
      <div class="row q-px-md q-pt-md">
        <q-resize-observer @resize="onResize" />

        <div class="q-pt-sm">
          <q-btn
            round
            icon="arrow_back"
            color="primary"
            size="md"
            padding="12px"
            @click="$emit('back')"
          />
        </div>

        <div class="col q-px-md">
          <div class="row flex-center no-wrap q-pb-md q-pt-sm">
            <q-btn
              v-if="goToBin.previous || goToBin.next"
              flat
              round
              dense
              icon="chevron_left"
              padding="2px"
              :disabled="!goToBin.previous"
              @click="$emit('goToPrevious')"
            />

            <div class="row flex-center text-center text-h5 q-px-md">
              {{ bin.name }}
            </div>

            <q-btn
              v-if="goToBin.previous || goToBin.next"
              flat
              round
              dense
              icon="chevron_right"
              padding="2px"
              :disabled="!goToBin.next"
              @click="$emit('goToNext')"
            />
          </div>

          <div class="gt-xs row justify-center">
            <div class="q-mr-lg">{{ FeedType }}</div>
            <div>Bal {{ numberWithCommas(bin.balance) }}</div>
          </div>
          <div class="lt-sm">
            <div class="text-center">{{ FeedType }}</div>
            <div class="text-center">
              Bal {{ numberWithCommas(bin.balance) }}
            </div>
          </div>
        </div>

        <div class="col-auto">
          <div class="column justify-center">
            <MasterIcon
              :percent="BinLevelPercent"
              :iconSrc="'bin-single.png'"
              :padding="'5px 5px 4px 5px'"
              :size="56"
            />

            <div class="text-caption text-center">
              {{ BinLevelPercent.toFixed(0) }}%
            </div>
          </div>
        </div>
      </div>
      <q-separator />

      <div class="row items-center q-px-sm q-py-md">
        <div class="col row justify-center q-pl-sm">
          <div class="col-12 row justify-center">
            <div style="width: 240px">
              <q-btn-toggle
                v-model="toggle"
                spread
                toggle-color="primary"
                :padding="$q.screen.xs ? '6px 4px' : '6px lg'"
                :options="[
                  { label: 'Feed Deliveries', value: 'deliveries' },
                  { label: 'Feeder Fill Ups', value: 'fillUps' }
                ]"
              />
            </div>
          </div>

          <div v-if="dateSearch" class="row items-center q-pt-md">
            <div>
              <q-btn
                icon="close"
                color="accent"
                size="sm"
                padding="2px"
                @click="setDefault"
              />
            </div>
            <div class="q-pl-sm" style="padding-top: 2px">
              {{ datesSelectedStr }}
            </div>
          </div>
        </div>

        <div class="col-auto q-pl-md">
          <div>
            <q-btn
              color="primary"
              size="sm"
              padding="7px"
              style="width: 92px"
              @click="fillBin"
            >
              <div style="font-size: 11px">Fill Bin</div>
            </q-btn>
          </div>
          <div style="padding-top: 10px">
            <q-btn
              color="grey-4"
              text-color="black"
              size="sm"
              padding="7px"
              style="width: 92px"
              @click="reconcileBin"
            >
              <div style="font-size: 11px">Reconcile</div>
            </q-btn>
          </div>
          <div style="padding-top: 10px">
            <q-btn
              color="grey-4"
              text-color="black"
              size="sm"
              padding="7px"
              style="width: 92px"
              :loading="DataQueryLoading"
              @click="dialogDates = true"
            >
              <div style="font-size: 11px">Date Search</div>
            </q-btn>
          </div>
        </div>
      </div>
    </div>

    <q-separator color="black" />
    <div class="row text-bold q-py-xs">
      <div class="col-auto" style="width: 70px; padding-left: 20px">Date</div>
      <div class="col text-center">
        <div v-if="toggle === 'deliveries'">
          <div class="gt-sm">Mill</div>
          <div class="lt-md">Mill / Type</div>
        </div>
        <div v-if="toggle === 'fillUps'">
          <div class="gt-sm">Feeder</div>
          <div class="lt-md">Feeder/Type</div>
        </div>
      </div>
      <div class="col-auto text-center" style="width: 72px">Lbs</div>
      <div class="gt-sm col text-center">Feed Type</div>
      <div class="gt-xs col text-center">Note</div>
      <div class="lt-sm col-auto text-center" style="width: 36px">Note</div>
      <div class="gt-xs col-auto" style="width: 48px">Attach</div>
      <div class="lt-sm col-auto row flex-center" style="width: 34px">
        <q-icon name="image" size="20px" color="primary" />
      </div>
      <div class="col-auto" :style="`padding-right: ${customPadding}px`">
        <div
          v-if="toggle === 'deliveries'"
          :style="`width: ${btnWidth}px`"
        ></div>
      </div>
    </div>
    <q-separator color="black" />

    <div id="entries-container" class="scroll">
      <q-resize-observer @resize="setCustomPadding" />
      <div
        v-if="BinEntries.length < 1"
        class="text-center text-h5 text-grey-6 q-pa-md"
      >
        No Entries
      </div>

      <q-virtual-scroll
        v-if="BinEntries.length"
        :style="`height: ${usableHeight}px`"
        :items="BinEntries"
        separator
      >
        <template v-slot="{ item }">
          <BinDetailRow
            :bin="bin"
            :entry="item"
            :toggle="toggle"
            @edit="editEntry"
            @requeryDates="requeryDates"
          />
        </template>
      </q-virtual-scroll>
    </div>

    <!-- Fill Bin Dialog -->
    <q-dialog persistent position="top" v-model="dialogFillBin">
      <q-card style="width: 450px" class="q-pa-md">
        <FillBin
          :transactionObj="transaction"
          :bin="bin"
          :action="action"
          :dateCopy="dateCopy"
          :dateSearch="dateSearch"
          :datesSelectedCopy="datesSelectedCopy"
          @close="dialogFillBin = false"
          @datesSelected="datesSelected"
        />
      </q-card>
    </q-dialog>

    <!-- Reconcile Dialog -->
    <q-dialog persistent position="top" v-model="dialogReconcile">
      <q-card style="width: 450px" class="q-pa-md">
        <q-form @submit="submitReconcile(transaction)">
          <div class="q-pb-sm">
            <q-input
              filled
              dense
              label="Date"
              v-model="transaction.date"
              lazy-rules
              :rules="[(val) => isDateValid(val) || 'Invalid Date']"
              hide-bottom-space
            >
              <template v-slot:append>
                <q-icon name="event" class="cursor-pointer" color="primary">
                  <q-popup-proxy
                    ref="qDateProxy"
                    transition-show="scale"
                    transition-hide="scale"
                  >
                    <q-date v-model="transaction.date" mask="MM/DD/YYYY">
                      <div class="row items-center justify-end">
                        <q-btn
                          v-close-popup
                          label="Close"
                          color="primary"
                          flat
                        />
                      </div>
                    </q-date>
                  </q-popup-proxy>
                </q-icon>
              </template>
            </q-input>
          </div>

          <div class="row q-pb-sm">
            <div>
              <q-btn
                :icon="reconcileAdd ? 'add' : 'remove'"
                :color="reconcileAdd ? 'positive' : 'accent'"
                size="md"
                class="q-mr-sm"
                padding="8px 10px"
                @click="reconcileAdd = !reconcileAdd"
              />
            </div>
            <div class="col">
              <q-input
                filled
                dense
                v-model.number="transaction.pounds"
                label="Lbs"
                step="any"
                min="0"
                mask="#####"
                type="number"
                lazy-rules
                hide-bottom-space
                :rules="[(val) => isNumValid(val) || '']"
              />
            </div>
          </div>

          <div class="q-pb-sm">
            <q-input
              filled
              dense
              autogrow
              v-model="transaction.memo"
              label="Note"
            />
          </div>

          <div class="row justify-end q-pt-xs">
            <q-btn
              unelevated
              label="Cancel"
              color="grey-4"
              text-color="primary"
              size="md"
              class="q-mr-sm"
              v-close-popup
            />
            <q-btn
              unelevated
              v-if="action === 'edit'"
              label="Delete"
              color="grey-4"
              text-color="accent"
              size="md"
              class="q-mr-sm"
              @click="deleteEntry"
            />
            <q-btn
              unelevated
              :label="
                action === 'edit'
                  ? 'Submit Edit'
                  : action === 'create'
                  ? 'Submit'
                  : 'TODO'
              "
              color="primary"
              size="md"
              type="submit"
            />
          </div>
        </q-form>
      </q-card>
    </q-dialog>

    <q-dialog full-height persistent position="top" v-model="dialogDates">
      <DatePicker
        :dateRangeSelection="dateRangeSelection"
        @datesSelected="datesSelected"
      />
    </q-dialog>
  </div>
</template>

<script>
import BinDetailRow from '@/components/feed/BinDetailRow.vue';
import MasterIcon from '@/components/icons/MasterIcon.vue';
import DatePicker from '@/components/general/DatePicker.vue';
import FillBin from '@/components/feed/dialogs/FillBin.vue';

import store from '@/store';
import {
  decoder,
  numberWithCommas,
  isNumValid,
  cloneObj,
  getPercentage,
  getScrollbarWidth
} from '@/lib/helpers';
import {
  createTransactionObj,
  getUID,
  guidMatchHelper
} from '@/store/transactionHelpers';
import { isDateValid, setTimeOfDay } from '@/lib/date-utils.js';
import { date } from 'quasar';

export default {
  name: 'BinEntries',
  components: {
    BinDetailRow,
    MasterIcon,
    DatePicker,
    FillBin
  },
  props: {
    bin: {
      type: Object,
      required: true
    },
    goToBin: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      action: 'create',
      btnWidth: 42,
      customPadding: 14,
      dateRangeSelection: 'prev90',
      dialogDates: false,
      dialogFillBin: false,
      dialogReconcile: false,
      dateCopy: null,
      dateCopyStr: null,
      dateSearch: false,
      datesSelectedCopy: null,
      datesSelectedStr: '',
      isDateValid,
      isNumValid,
      numberWithCommas,
      reconcileAdd: true,
      poundsCopy: null,
      store,
      toggle: 'deliveries',
      transaction: {},
      usableHeight: 1000
    };
  },
  methods: {
    datesSelected(dates) {
      this.dateRangeSelection = dates.dateRangeSelected;
      this.datesSelectedCopy = cloneObj(dates);
      const fromStr = date.formatDate(dates.from, 'MM/DD/YYYY');
      const toStr = date.formatDate(dates.to, 'MM/DD/YYYY');
      this.datesSelectedStr = `${fromStr} - ${toStr}`;

      const from = parseInt(date.formatDate(dates.from, 'X'));
      const end = date.endOfDate(dates.to, 'day');
      const to = parseInt(date.formatDate(end, 'X'));

      const dateInfo = {
        storeInfo: {
          farmId: this.$store.state.farm.id,
          action: 'getBinEntriesByDate',
          queryProp: 'binEntries'
        },
        from,
        to,
        binGrainId: this.bin.grain_id
      };

      store.dispatch('queryData', dateInfo);
      this.dialogDates = false;

      this.dateSearch = true;
    },
    deleteEntry() {
      this.$q
        .dialog({
          title: 'Confirm',
          message: 'Are you sure you want to delete this entry?',
          ok: {
            color: 'accent',
            label: 'Delete',
            icon: 'delete'
          },
          cancel: {
            label: 'Cancel',
            color: 'grey-4',
            textColor: 'black'
          },
          persistent: true,
          focus: 'none'
        })
        .onOk(() => {
          this.deleteEntryConfirmed();
        });
    },
    deleteEntryConfirmed() {
      const transaction = cloneObj(this.transaction);
      transaction.storeInfo.action = 'deleteBinEntry';
      transaction.storeInfo.category = 'binEntries';
      transaction.storeInfo.binId = this.bin.id;
      transaction.storeInfo.binGrainId = this.bin.grain_id;

      transaction.storeInfo.binBal =
        this.dialogReconcile && !this.reconcileAdd
          ? parseFloat(this.bin.balance) + transaction.pounds
          : parseFloat(this.bin.balance) - transaction.pounds;

      guidMatchHelper(transaction, this.action);

      store.dispatch('publish', transaction);

      this.$q.notify({
        message: 'Entry Deleted',
        color: 'accent',
        icon: 'delete'
      });

      if (this.dateSearch) {
        setTimeout(() => {
          this.datesSelected(this.datesSelectedCopy);
        }, 300);
      }

      this.dialogFillBin = false;
      this.dialogReconcile = false;
    },
    editEntry(entry) {
      this.action = 'edit';
      this.transaction = {
        ...createTransactionObj(),
        ...cloneObj(entry)
      };

      this.dateCopy = this.transaction.date;
      this.transaction.date = date.formatDate(
        this.transaction.date * 1000,
        'MM/DD/YYYY'
      );
      this.dateCopyStr = this.transaction.date;
      this.poundsCopy = parseFloat(this.transaction.pounds);

      if (!this.transaction.mill_name) {
        this.dialogReconcile = true;
        this.reconcileAdd =
          this.transaction.to_account === this.bin.grain_id ? true : false;
      } else {
        this.dialogFillBin = true;
      }
    },
    fillBin() {
      this.action = 'create';
      this.transaction = createTransactionObj();
      this.poundsCopy = 0;

      this.transaction.date = date.formatDate(new Date(), 'MM/DD/YYYY');

      this.dialogFillBin = true;
    },
    reconcileBin() {
      this.action = 'create';
      this.transaction = createTransactionObj();
      this.transaction.date = date.formatDate(new Date(), 'MM/DD/YYYY');
      this.poundsCopy = 0;

      this.dialogReconcile = true;
    },
    submitReconcile(transactionObj) {
      const transaction = cloneObj(transactionObj);

      const dateStr = this.transaction.date;
      transaction.date = setTimeOfDay(dateStr);

      if (this.action === 'edit' && this.dateCopyStr === dateStr) {
        transaction.date = this.dateCopy;
      }

      if (this.action === 'create') {
        transaction.id = 0;
      }

      transaction.to_account = this.bin.grain_id;

      if (this.dialogReconcile) {
        const grainId = this.$store.state.farm.settings.loss.grain_id;

        if (this.reconcileAdd) {
          transaction.from_account = grainId;
        }

        if (!this.reconcileAdd) {
          transaction.to_account = grainId;
          transaction.from_account = this.bin.grain_id;
        }

        transaction.feed_type = null;
        transaction.feed_type_name = null;
      }

      this.submitReconcileHelper(transaction);
      store.dispatch('publish', transaction);

      this.dialogReconcile = false;

      this.$q.notify({
        message: `Entry ${this.action === 'create' ? 'Added' : 'Updated'}`,
        icon: 'check',
        color: 'primary'
      });

      if (this.dateSearch) {
        setTimeout(() => {
          this.datesSelected(this.datesSelectedCopy);
        }, 300);
      }
    },
    submitReconcileHelper(transaction) {
      // This helper adds information necessary for the submit process
      // but is not part of the transaction table - (mutates transaction)
      // Some of this information injected here is normally provided by
      // left joins on the server, but we push this update to state
      // immediately so we can accomodate offline entries

      transaction.storeInfo.action =
        this.action === 'create' ? 'createBinEntry' : 'updateBinEntry';
      transaction.storeInfo.binBal =
        parseFloat(this.bin.balance) + (transaction.pounds - this.poundsCopy);
      transaction.storeInfo.category = 'binEntries';
      transaction.storeInfo.binId = this.bin.id;
      transaction.storeInfo.binGrainId = this.bin.grain_id;

      guidMatchHelper(transaction, this.action);
    },
    onResize() {
      const adjustableHeight =
        document.getElementById('adjustable').clientHeight;

      this.usableHeight = store.state.pageHeight - 82 - adjustableHeight;
    },
    requeryDates() {
      if (this.dateSearch) {
        setTimeout(() => {
          this.datesSelected(this.datesSelectedCopy);
        }, 500);
      }
    },
    setCustomPadding() {
      const div = document.getElementById('entries-container');

      let hasScrollBar = div.scrollHeight < this.BinEntries.length * 40;

      this.customPadding = hasScrollBar ? this.ScrollbarWidth : 0;
      this.btnWidth = this.$q.screen.xs ? 42 : 52;
    },
    setDefault() {
      this.dialogDates = false;
      this.dateSearch = false;
    }
  },
  computed: {
    BinEntries() {
      return this.dateSearch
        ? this.BinQueriedEntries
        : this.toggle === 'fillUps'
        ? this.bin.withdrawals
        : this.bin.deliveries;
    },
    BinLevelPercent() {
      return getPercentage(this.bin.balance, this.bin.capacity);
    },
    BinQueriedEntries() {
      return this.toggle === 'fillUps'
        ? this.$store.state.dataQueries.binEntries?.withdrawals ?? []
        : this.$store.state.dataQueries.binEntries?.deliveries ?? [];
    },
    DataQueryLoading() {
      return store.state.dataQueryLoading;
    },
    FeedType() {
      let feedType = '---';
      for (const entry of this.bin.deliveries) {
        if (entry.mill_name) {
          feedType = entry.feed_type_name;
          break;
        }
      }

      return feedType;
    },
    ScrollbarWidth() {
      return getScrollbarWidth();
    }
  }
};
</script>

<style></style>
