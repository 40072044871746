<template>
  <div>
    <div class="row items-center q-py-sm">
      <div class="col-auto q-pl-sm" style="width: 70px">
        {{ parseTimeStamp(entry.date, 'short') }}
      </div>

      <div class="col q-px-sm">
        <div
          v-if="toggle === 'feedings' && entry.feeder_name"
          class="text-center"
        >
          <div :class="$q.screen.lt.md && 'text-caption'">
            {{ entry.feeder_name }}
          </div>
          <div class="lt-md" :class="$q.screen.lt.md && 'text-caption'">
            {{ entry.feed_type_name }}
          </div>
        </div>

        <div
          v-if="toggle === 'harvests' && entry.processor_name"
          class="text-center"
        >
          <div
            :class="$q.screen.lt.md && 'text-caption'"
            class="row flex-center"
          >
            {{ entry.processor_name }}
          </div>
          <div class="lt-md" :class="$q.screen.lt.md && 'text-caption'">
            {{ entry.feed_type_name }}
          </div>
        </div>

        <div
          v-if="
            toggle === 'feedings' &&
            !entry.feeder_name &&
            entry.to_account !== pond.grain_id
          "
          class="text-accent text-center"
        >
          Reconcile/Loss
        </div>
        <!-- <div -->
        <!--   v-if=" -->
        <!--     toggle === 'feedings' &#38;&#38; -->
        <!--     !entry.feeder_name &#38;&#38; -->
        <!--     entry.to_account === pond.grain_id -->
        <!--   " -->
        <!--   class="text-primary text-center" -->
        <!-- > -->
        <!--   Reconcile/Gain -->
        <!-- </div> -->
      </div>

      <div class="col-auto text-center q-px-xs" style="width: 72px">
        {{ numberWithCommas(entry.pounds) }}
      </div>

      <div class="gt-sm col text-center q-px-sm">
        {{ entry.feed_type_name }}
      </div>

      <div class="gt-xs col text-center q-px-sm">
        {{ entry.memo }}
      </div>

      <div class="lt-sm col-auto q-pr-sm" style="width: 40px">
        <q-btn
          v-if="entry.memo"
          unelevated
          color="grey-2"
          text-color="primary"
          size="sm"
          padding="6px"
          @click="displayNote(entry.memo)"
        >
          <q-icon name="sticky_note_2" size="20px" />
        </q-btn>
      </div>
      <!-- v-if=" toggle === 'feedings' &#38;&#38; !entry.feeder_name &#38;&#38; entry.to_account !== -->
      <!-- pond.grain_id " -->
      <!--  -->
      <div
        v-if="toggle === 'feedings' && entry.feeder_name"
        class="col-auto q-pr-sm"
      >
        <q-btn
          unelevated
          color="grey-4"
          text-color="accent"
          size="sm"
          :padding="$q.screen.gt.sm ? '2px 8px' : '6px'"
          @click="$emit('edit', entry)"
        >
          <q-icon name="edit" size="20px" />
        </q-btn>
      </div>
      <div v-else class="col-auto q-pr-sm">
        <q-btn
          unelevated
          color="grey-4"
          text-color="accent"
          size="sm"
          :padding="$q.screen.gt.sm ? '2px 8px' : '6px'"
          @click="dialogLockedInfo = true"
        >
          <q-icon name="lock" size="20px" />
        </q-btn>
      </div>
    </div>
    <q-separator />

    <!-- Locked Info Dialog -->
    <q-dialog v-model="dialogLockedInfo">
      <q-card style="width: 450px">
        <div class="q-pa-md text-subtitle1">
          This entry can be edited in ponds (fish loss).
        </div>
        <q-separator />

        <div class="row justify-end q-pa-sm">
          <q-btn
            unelevated
            label="ok"
            color="grey-3"
            text-color="black"
            padding="sm lg"
            v-close-popup
          />
        </div>
      </q-card>
    </q-dialog>

    <!-- Reconcile Dialog -->
    <q-dialog persistent position="top" v-model="dialogNotes">
      <q-card style="width: 450px">
        <div class="row items-center q-pa-sm">
          <q-space />
          <div class="text-subtitle1">
            {{ parseTimeStamp(entry.date, 'short') }}
          </div>
          <q-space />
          <div>
            <q-btn icon="clear" color="primary" v-close-popup />
          </div>
        </div>
        <q-separator />
        <div class="q-pa-md">
          <span class="text-bold"> Note: </span>
          <span>
            {{ entry.memo }}
          </span>
        </div>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import { numberWithCommas } from '@/lib/helpers';
import { parseTimeStamp } from '@/lib/date-utils.js';

export default {
  name: 'PondEntries',
  props: {
    pond: {
      type: Object,
      required: true
    },
    entry: {
      type: Object,
      required: true
    },
    toggle: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      dialogLockedInfo: false,
      dialogNotes: false,
      numberWithCommas,
      parseTimeStamp
    };
  },
  methods: {
    displayNote() {
      this.dialogNotes = true;
    }
  }
};
</script>

<style></style>
