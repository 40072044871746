<template>
  <div>
    <div>
      <div class="col row items-center" style="padding: 10px 0 10px 0">
        <div class="col-7 cursor-pointer" @click="dialogInfo = true">
          <div class="row items-center">
            <div class="col q-px-sm">
              <span
                style="border-bottom: 1px solid black"
                class="text-subtitle1"
              >
                {{ pond.name }}
              </span>
            </div>
            <div class="col-7 row items-center">
              <div v-if="LastTreatment" class="col-6 q-pl-sm ellipsis-2-lines">
                <span>
                  {{ numberWithCommas(LastTreatment.qty) }}
                </span>
                <span class="text-caption text-grey-8"
                  >{{ LastTreatment.size }}
                </span>
              </div>

              <div v-else class="col-6 text-grey-5 q-pl-sm">
                <div>- - -</div>
              </div>

              <div
                v-if="CurrentTreatment"
                class="col-6 q-pl-sm ellipsis-2-lines"
              >
                <span>
                  {{ numberWithCommas(CurrentTreatment.qty) }}
                </span>
                <span class="text-caption text-grey-8"
                  >{{ CurrentTreatment.size }}
                </span>
              </div>

              <div v-else class="col-6 text-grey-5 q-pl-sm">
                <div>- - -</div>
              </div>
            </div>
          </div>
        </div>

        <div class="col row items-center">
          <q-btn
            unelevated
            color="grey-4"
            textColor="black"
            padding="10px 0px"
            class="full-width"
            size="md"
            @click="treatPond(pond)"
            @click.stop
          >
            <div class="row items-center justify-around full-width">
              <div style="width: 30px"></div>
              <div>Treat</div>
              <div v-if="CurrentTreatment" style="width: 30px; height: 24px">
                <q-icon name="task_alt" color="primary" />
              </div>
              <div v-else style="width: 30px; height: 24px"></div>
            </div>
          </q-btn>
        </div>
      </div>
    </div>

    <!-- Info Dialog -->
    <q-dialog position="top" full-hight v-model="dialogInfo">
      <q-card style="width: 400px">
        <div class="row items-center justify-between q-pa-xs">
          <div style="width: 44px"></div>
          <div class="col text-h6 text-center">
            {{ pond.name }}
          </div>
          <div>
            <q-btn
              unelevated
              icon="close"
              color="grey-3"
              text-color="black"
              size="md"
              padding="2px 10px"
              v-close-popup
            />
          </div>
        </div>
        <q-separator />

        <div class="row text-subtitle2">
          <div class="col-auto text-center" style="width: 70px">Date</div>
          <div class="col text-center">Qty</div>
          <div class="col text-center">Type</div>
          <div class="col-auto text-center" style="width: 44px">Note</div>
          <div class="col-auto" style="width: 52px"></div>
        </div>
        <q-separator />

        <div v-for="(treatment, i) in LastTenEntries" :key="treatment.id">
          <div class="row text-body2 q-px-xs">
            <div
              class="col-auto q-pl-xs"
              style="width: 70px; padding: 12px 0 12px 0"
            >
              {{ parseTimeStamp(treatment.date, 'short') }}
            </div>

            <div class="col text-center" style="padding-top: 12px">
              <span>
                {{ numberWithCommas(treatment.qty) }}
              </span>
              <span class="text-caption text-grey-8"
                >{{ treatment.size }}
              </span>
            </div>

            <div class="col text-center" style="padding-top: 12px">
              <span class="text-caption text-grey-8"
                >{{ treatment.name }}
              </span>
            </div>

            <div class="col-auto row items-center q-px-sm" style="width: 56px">
              <q-btn
                v-if="treatment.memo"
                unelevated
                icon="sticky_note_2"
                color="grey-3"
                text-color="black"
                size="md"
                padding="2px 10px"
              >
                <q-menu
                  transition-show="flip-right"
                  transition-hide="flip-left"
                  anchor="bottom right"
                  self="top right"
                >
                  <q-card
                    style="min-width: 50px; max-width: 300px"
                    class="q-pa-sm"
                  >
                    <div class="text-center">
                      {{ treatment.memo }}
                    </div>
                  </q-card>
                </q-menu>
              </q-btn>
            </div>

            <div class="col-auto row items-center">
              <q-btn
                unelevated
                icon="edit"
                color="grey-3"
                text-color="accent"
                padding="2px 10px"
                @click="editTreatment(treatment)"
              />
            </div>
          </div>
          <q-separator v-if="i < 9" color="grey-2" />
        </div>
      </q-card>
    </q-dialog>

    <!-- Treat Pond Dialog -->
    <q-dialog persistent full-height position="top" v-model="dialogTreatPond">
      <q-card style="width: 450px">
        <div class="text-h6 text-center q-py-sm">
          {{ pond.name }}
        </div>
        <q-separator />

        <div class="scroll" style="max-height: calc(100vh - 80px)">
          <q-form @submit="submitTreatment()">
            <div class="q-px-lg q-pt-sm q-pb-sm">
              <div class="q-pb-sm">
                <q-input
                  outlined
                  dense
                  label="Date"
                  v-model="treatment.date"
                  lazy-rules
                  :rules="[(val) => isDateValid(val) || 'Invalid Date']"
                  hide-bottom-space
                >
                  <template v-slot:append>
                    <q-icon name="event" class="cursor-pointer" color="primary">
                      <q-popup-proxy
                        ref="qDateProxy"
                        transition-show="scale"
                        transition-hide="scale"
                      >
                        <q-date v-model="treatment.date" mask="MM/DD/YYYY">
                          <div class="row items-center justify-end">
                            <q-btn
                              v-close-popup
                              label="Close"
                              color="primary"
                              flat
                            />
                          </div>
                        </q-date>
                      </q-popup-proxy>
                    </q-icon>
                  </template>
                </q-input>
              </div>

              <div class="q-pb-sm">
                <q-select
                  v-model="treatment.treatment"
                  label="Select Treatment"
                  dense
                  outlined
                  :options="TreatmentOptions"
                  emit-value
                  map-options
                  lazy-rules
                  hide-bottom-space
                  :rules="[(val) => !!val || 'Please Select A Treatment']"
                />
              </div>

              <div>
                <q-input
                  outlined
                  dense
                  autogrow
                  v-model="treatment.memo"
                  label="Note"
                />
              </div>
            </div>
            <q-separator />

            <div class="q-pa-md">
              <div class="q-px-sm q-pb-sm">
                <div class="text-center">Enter Amount</div>
                <div
                  class="row items-center text-h6"
                  style="
                    height: 50px;
                    padding: 0px 20px;
                    border: 1px solid black;
                    border-radius: 30px;
                  "
                >
                  <div v-if="treatment.qty">
                    {{ TreatmentAmount }}
                  </div>
                  <q-space />

                  <div>
                    {{ TreatmentQtyLabel }}
                  </div>
                </div>

                <div
                  v-if="qtyErr"
                  class="text-accent"
                  style="padding: 0px 22px"
                >
                  Please enter a value
                </div>
              </div>

              <div class="row">
                <div class="col-4" v-for="n in 9" :key="n">
                  <div class="q-pa-sm">
                    <q-btn
                      rounded
                      color="primary"
                      class="full-width"
                      padding="10px"
                      @click="addToQty(n)"
                    >
                      <div class="text-h6">
                        {{ n }}
                      </div>
                    </q-btn>
                  </div>
                </div>

                <div class="col-4 q-pa-sm">
                  <q-btn
                    rounded
                    color="primary"
                    class="full-width"
                    padding="10px"
                    @click="addToQty(0)"
                  >
                    <div class="text-h6">0</div>
                  </q-btn>
                </div>

                <div class="col-4 q-pa-sm">
                  <q-btn
                    rounded
                    color="primary"
                    class="full-width"
                    padding="10px"
                    @click="addToQty('.')"
                  >
                    <div class="text-h6">.</div>
                  </q-btn>
                </div>

                <div class="col-4 q-pa-sm">
                  <q-btn
                    rounded
                    color="primary"
                    class="full-width"
                    padding="10px"
                    @click="backspace"
                  >
                    <div style="padding: 2px 4px 2px 0">
                      <q-icon name="backspace" size="28px" />
                    </div>
                  </q-btn>
                </div>
              </div>
            </div>
            <q-separator />

            <div class="row q-py-lg q-px-lg">
              <div class="col q-pr-sm">
                <q-btn
                  rounded
                  label="Cancel"
                  color="grey-4"
                  text-color="black"
                  size="md"
                  padding="10px md"
                  class="full-width"
                  v-close-popup
                />
              </div>
              <div v-if="action === 'update'" class="col q-px-sm">
                <q-btn
                  rounded
                  label="Delete"
                  color="grey-4"
                  text-color="accent"
                  size="md"
                  padding="10px md"
                  class="full-width"
                  @click="deleteTreatment"
                />
              </div>
              <div class="col q-pl-sm">
                <q-btn
                  rounded
                  label="Submit"
                  color="secondary"
                  text-color="black"
                  size="md"
                  padding="10px md"
                  class="full-width"
                  type="submit"
                />
              </div>
            </div>
          </q-form>
        </div>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import { decoder, numberWithCommas, isNumValid, cloneObj } from '@/lib/helpers';
import {
  createTransactionObj,
  getUID,
  guidMatchHelper
} from '@/store/transactionHelpers';
import { parseTimeStamp, isDateValid, setTimeOfDay } from '@/lib/date-utils.js';
import { date } from 'quasar';
import store from '@/store';

function defaultTreatment() {
  return {
    id: null,
    user_id: store.state.user.id,
    treatment: null,
    qty: '',
    date: date.formatDate(new Date(), 'MM/DD/YYYY'),
    memo: '',
    guid: null,
    storeInfo: {
      farmId: store.state.selectedFarm.farm_id
    }
  };
}

export default {
  name: 'TreatPondRow',
  props: {
    pond: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      action: 'create',
      dateCopy: null,
      dateCopyStr: null,
      dialogTreatPond: false,
      dialogInfo: false,
      isDateValid,
      numberWithCommas,
      parseTimeStamp,
      poundsCopy: null,
      qtyErr: false,
      treatment: defaultTreatment()
    };
  },
  // mounted() {},
  methods: {
    addToQty(n) {
      // if more than 2 decimal places, return
      if (
        this.treatment.qty.split('.')[1] &&
        this.treatment.qty.split('.')[1].length > 1
      ) {
        return;
      }

      if (parseInt(this.treatment.qty) > 99999) {
        return;
      }

      if (this.treatment.qty.length > 0 && n === 0) {
        this.treatment.qty += n.toString();
        return;
      }

      this.treatment.qty = parseFloat(this.treatment.qty + n).toString();

      // if decimal add decimal but only if there is not already a decimal
      const decimalCount = this.treatment.qty.split('.').length - 1;

      if (n === '.' && decimalCount < 1) {
        // check if is NaN - catches case where user enters '.'
        if (isNaN(parseFloat(this.treatment.qty))) {
          this.treatment.qty = '0.';
          return;
        }

        this.treatment.qty += '.';
      }
    },
    backspace() {
      if (this.treatment.qty.length === 1) {
        this.treatment.qty = '0';
        return;
      }

      this.treatment.qty = this.treatment.qty.slice(0, -1);
    },
    deleteTreatment() {
      this.$q
        .dialog({
          title: 'Confirm Delete',
          message: 'Are you sure you want to delete this treatment?',
          ok: {
            icon: 'delete',
            label: 'Delete',
            color: 'accent'
          },
          cancel: {
            label: 'Cancel',
            color: 'grey-3',
            textColor: 'primary'
          },
          focus: 'none',
          persistent: true
        })
        .onOk(() => {
          this.action = 'delete';
          this.submitTreatment();
        });
    },
    editTreatment(treatment) {
      this.action = 'update';

      this.treatment = {
        ...defaultTreatment(),
        ...treatment
      };

      this.dateCopy = this.treatment.date;
      this.treatment.date = date.formatDate(
        new Date(treatment.date * 1000),
        'MM/DD/YYYY'
      );
      this.dateCopyStr = this.treatment.date;

      this.treatment.qty = this.treatment.qty.toString();

      this.dialogTreatPond = true;
    },
    treatPond(pond) {
      this.action = 'create';
      this.treatment = defaultTreatment();
      this.dialogTreatPond = true;
    },
    submitTreatment() {
      const treatment = cloneObj(this.treatment);

      if (treatment.qty === '0') {
        this.qtyErr = true;
        return;
      }
      this.qtyErr = false;

      const dateStr = this.treatment.date;
      treatment.date = setTimeOfDay(dateStr);

      if (
        (this.action === 'update' || this.action === 'delete') &&
        this.dateCopyStr === dateStr
      ) {
        treatment.date = this.dateCopy;
      }

      if (this.action === 'create') {
        treatment.id = 0;
      }

      const treatmentObj = this.TreatmentOptions.find(
        (x) => x.value === treatment.treatment
      );

      treatment.guid = getUID();
      treatment.user_id = store.state.user.user_id;
      treatment.name = treatmentObj.name;
      treatment.size = treatmentObj.size;
      treatment.storeInfo.action = this.action + 'TreatPond';
      treatment.storeInfo.category = 'treatPond';
      treatment.storeInfo.pondId = this.pond.id;
      treatment.qty = +parseFloat(treatment.qty).toFixed(2);

      guidMatchHelper(treatment, this.action);

      store.dispatch('publish', treatment);

      this.dialogTreatPond = false;

      this.$q.notify({
        message: `Entry ${
          this.action === 'create'
            ? 'Added'
            : this.action === 'update'
            ? 'Updated'
            : 'Deleted'
        }`,
        icon: 'check',
        color: this.action === 'delete' ? 'negative' : 'primary'
      });
    }
  },
  computed: {
    CurrentTreatment() {
      const currDate = date.formatDate(new Date(), 'YYYY-MM-DD');

      // if no initial treatment, return null
      if (this.pond.treatHistory.length === 0) {
        return null;
      }

      // find the most recent treatment
      const currTreatment = this.pond.treatHistory.reduce((a, b) => {
        if (a.date > b.date) {
          return a;
        }
        return b;
      });

      const currTreatmentDate = date.formatDate(
        currTreatment.date * 1000,
        'YYYY-MM-DD'
      );

      return currDate === currTreatmentDate ? currTreatment : null;
    },
    LastTenEntries() {
      return this.pond.treatHistory.slice(0, 10);
    },
    LastTreatment() {
      const currDate = date.formatDate(new Date(), 'YYYY-MM-DD');
      let lastTreatment = null;

      let lastDate = null;
      let continued = false;
      for (let i = 0; i < this.pond.treatHistory.length; i++) {
        const treatment = this.pond.treatHistory[i];

        const treatDate = date.formatDate(
          new Date(treatment.date * 1000),
          'YYYY-MM-DD'
        );

        if (treatDate === currDate && !continued) {
          continued = true;
          continue;
        } else {
          lastTreatment = cloneObj(treatment);

          break;
        }
      }

      return lastTreatment;
    },
    TreatmentAmount() {
      let amount = parseFloat(this.treatment.qty);

      const decimalCount = this.treatment.qty.split('.').length - 1;

      if (this.treatment.qty.endsWith('.') && decimalCount === 1) {
        amount += '.';
      }

      if (this.treatment.qty === '.') {
        return '0.';
      }

      return numberWithCommas(amount);
    },
    TreatmentOptions() {
      const treatments = [];
      for (const treatment of store.state.farm.treatments) {
        treatments.push({
          value: treatment.id,
          name: treatment.name,
          label: treatment.name,
          size: treatment.size
        });
      }

      // Old feedx data has treatment IDs that
      // are from other farms
      if (this.dialogTreatPond) {
        const hasId = treatments.some(
          (x) => x.value === this.treatment.treatment
        );

        if (!hasId) {
          treatments.push({
            value: this.treatment.treatment,
            name: this.treatment.name,
            label: this.treatment.name,
            size: this.treatment.size
          });
        }
      }

      return treatments;
    },
    TreatmentQtyLabel() {
      return (
        this.TreatmentOptions.find((x) => x.value === this.treatment.treatment)
          ?.size ?? ''
      );
    }
  }
};
</script>

<style></style>
