<template>
  <div class="q-px-md q-py-sm">
    <FullCalendar ref="fullCalendar" :options="calendarOptions" />

    <!-- Day Events Dialog -->
    <q-dialog v-model="dialogDayEvents">
      <q-card style="width: 400px">
        <div class="row items-center q-py-sm">
          <div class="col text-center text-h6">{{ dayDate }}</div>
        </div>
        <q-separator />

        <div
          class="scroll q-pa-md"
          :style="`max-height: ${store.state.pageHeight - 200}px`"
        >
          <div v-for="(event, i) in dayEvents" :key="(event.date = i)">
            <div
              class="rounded-borders text-white q-px-xs q-mb-xs"
              :style="`background-color: ${event.backgroundColor}`"
            >
              {{ event.title }}
            </div>
          </div>
        </div>
        <q-separator />

        <div class="row justify-end q-pa-md">
          <q-btn label="Done" color="grey-2" text-color="black" v-close-popup />
        </div>
      </q-card>
    </q-dialog>

    <!-- Filter Dialog -->
    <q-dialog v-model="dialogFilter" persistent position="top">
      <q-card style="width: 300px">
        <div class="row items-center q-pa-md">
          <div class="col text-center text-h5">Filter</div>
          <div class="col-auto">
            <q-btn icon="close" color="primary" v-close-popup />
          </div>
        </div>
        <q-separator />

        <div class="q-pa-md">
          <div v-for="(option, key) in filterOptions" :key="key">
            <q-toggle
              v-model="option.display"
              :label="option.label"
              color="primary"
              @input="setEventFilter(key)"
            />
          </div>
        </div>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import FullCalendar from '@fullcalendar/vue';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';

import store from '@/store';
import {
  decoder,
  numberWithCommas,
  isNumValid,
  cloneObj,
  getPercentage,
  getScrollbarWidth,
  smartCompare
} from '@/lib/helpers';
import { date } from 'quasar';
import AuthUtils from '@/lib/auth-utils';
import { upperCaseFirst } from '@/lib/helpers';

export default {
  name: 'CalendarPage',
  components: {
    FullCalendar
  },
  data() {
    return {
      calendarOptions: {
        plugins: [dayGridPlugin, interactionPlugin],
        initialView: 'dayGridMonth',
        events: [],
        height: store.state.pageHeight - 75,
        dayMaxEventRows: true,
        views: {
          dayGrid: {
            dayMaxEventRows: 3
          }
        },
        dateClick: (info) => {
          this.displayDate(info.dateStr);
        },
        datesSet: (info) => {
          this.setDayMaxEventRows(info);
        },
        eventClick: (info) => {
          const dateStr = date.formatDate(info.event.start, 'YYYY-MM-DD');
          this.displayDate(dateStr);
        },
        buttonText: {
          today: 'Today',
          month: 'Month',
          week: 'Week'
        },
        customButtons: {
          filterButton: {
            text: 'Filter',
            click: () => {
              this.dialogFilter = true;
            }
          },
          noteButton: {
            text: 'Add Note',
            click: () => {
              this.addNote();
            }
          }
        },
        headerToolbar: {
          left: 'prev,next today',
          center: 'title',
          right: 'filterButton dayGridMonth,dayGridWeek'
          // right: 'filterButton noteButton dayGridMonth,dayGridWeek'
        }
      },
      dayDate: '',
      dayEvents: [],
      dialogDayEvents: false,
      dialogFilter: false,
      filterOptions: {
        allEvents: {
          display: true,
          label: 'All Events'
        },
        feed: {
          display: true,
          label: 'Feed'
        },
        treat: {
          display: true,
          label: 'Notes'
        },
        stock: {
          display: true,
          label: 'Stock'
        },
        harvest: {
          display: true,
          label: 'Harvest'
        },
        loss: {
          display: true,
          label: 'Loss'
        },
        transfer: {
          display: true,
          label: 'Transfer'
        },
        treat: {
          display: true,
          label: 'Treat'
        },
        oxygen: {
          display: true,
          label: 'Oxygen'
        },
        flavor: {
          display: true,
          label: 'Flavor'
        }
      },
      store
    };
  },
  mounted() {
    this.setEvents();
    const pondGrainIds = [];
    for (const pond of store.state.farm.ponds) {
      pondGrainIds.push(pond.grain_id);
    }

    const queryObj = {
      storeInfo: {
        farmId: store.state.farm.id,
        action: 'getAllPondFeedings',
        queryProp: 'allFeedings'
      },
      pondGrainIds
    };

    store.dispatch('queryData', queryObj);
  },
  methods: {
    addNote() {
      console.log('%cadd note', 'color: orange');
    },
    displayDate(dateStr) {
      // account for timezone offset
      const [year, month, day] = dateStr.split('-').map(Number);

      this.dayDate = date.formatDate(
        new Date(year, month - 1, day),
        'MMM, D YYYY'
      );

      // for some reason filter needs to be done after clone
      const events = cloneObj(this.calendarOptions.events);

      this.dayEvents = events
        .filter((x) => x.date === dateStr)
        .sort((a, b) => {
          return a.title.localeCompare(b.title);
        });

      this.dialogDayEvents = true;
    },
    setDayMaxEventRows(info) {
      if (info.view.type === 'dayGridDay') {
        this.calendarOptions.views.dayGrid.dayMaxEventRows = this.$q.screen.xs
          ? 12
          : 20;
      } else if (info.view.type === 'dayGridWeek') {
        this.calendarOptions.views.dayGrid.dayMaxEventRows = this.$q.screen.xs
          ? 10
          : 16;
      } else {
        this.calendarOptions.views.dayGrid.dayMaxEventRows = this.$q.screen.xs
          ? 2
          : 3;
      }
    },
    setEvents() {
      this.calendarOptions.events = [];
      const feedingEvents = new Map();
      const noteEvents = new Map();

      for (const pond of store.state.farm.ponds) {
        for (const feeding of pond.feedings) {
          const dateStr = date.formatDate(feeding.date * 1000, 'YYYY-MM-DD');

          if (feedingEvents.has(dateStr)) {
            feedingEvents.set(
              dateStr,
              feedingEvents.get(dateStr) + feeding.pounds
            );
          } else {
            feedingEvents.set(dateStr, feeding.pounds);
          }
        }

        for (const historyObj of pond.growth.ManageHistory) {
          const dateStr = date.formatDate(historyObj.date * 1000, 'YYYY-MM-DD');

          if (this.filterOptions.stock.display) {
            if (historyObj.transfer_type === 'Stock') {
              const stockStr = `Stock: ${pond.name} - ${
                historyObj.transfer_name
              }
                ${numberWithCommas(historyObj.head)} head @ ${
                historyObj.size
              } lbs
                ${historyObj.memo}`;

              this.calendarOptions.events.push({
                title: decoder(stockStr),
                date: dateStr,
                backgroundColor: '#1c3d5a',
                borderColor: '#1c3d5a'
              });
            }
          }

          if (this.filterOptions.harvest.display) {
            if (historyObj.transfer_type === 'Harvest') {
              const harvestStr = `Harvest: ${pond.name} - ${
                historyObj.transfer_name
              }
                ${numberWithCommas(historyObj.head)} head @ ${
                historyObj.size
              } lbs
                ${historyObj.memo}`;

              this.calendarOptions.events.push({
                title: decoder(harvestStr),
                date: dateStr,
                backgroundColor: '#228b22',
                borderColor: '#228b22'
              });
            }
          }

          if (this.filterOptions.loss.display) {
            if (historyObj.transfer_type === 'Loss') {
              const mortalityStr = `Loss: ${pond.name} - ${numberWithCommas(
                historyObj.head
              )} head @ ${historyObj.size} lbs - ${historyObj.memo}`;

              this.calendarOptions.events.push({
                title: decoder(mortalityStr),
                date: dateStr,
                backgroundColor: '#a41e39',
                borderColor: '#a41e39'
              });
            }
          }

          if (this.filterOptions.transfer.display) {
            if (historyObj.transfer_type === 'Transfer In') {
              const transferStr = `Transfer: ${historyObj.transfer_name} to ${
                pond.name
              }
                ${numberWithCommas(historyObj.head)} head @ ${
                historyObj.size
              } lbs
                ${historyObj.memo}`;

              this.calendarOptions.events.push({
                title: decoder(transferStr),
                date: dateStr,
                backgroundColor: '#8b008b',
                borderColor: '#8b008b'
              });
            }
          }
        }

        if (this.filterOptions.treat.display) {
          for (const treatment of pond.treatHistory) {
            const dateStr = date.formatDate(
              treatment.date * 1000,
              'YYYY-MM-DD'
            );
            const treatmentStr = `Treat: ${pond.name} - ${treatment.qty} ${treatment.size}
              ${treatment.name}`;

            this.calendarOptions.events.push({
              title: decoder(treatmentStr),
              date: dateStr,
              backgroundColor: '#4b2e83',
              borderColor: '#4b2e83'
            });
          }
        }

        if (this.filterOptions.oxygen.display) {
          for (const oxygenRecord of pond.oxygenRecords) {
            const dateStr = date.formatDate(
              oxygenRecord.date * 1000,
              'YYYY-MM-DD'
            );
            const oxygenStr = `Oxygen: ${pond.name}
              Handheld: ${oxygenRecord.handheld_reading}
              Monitor: ${oxygenRecord.monitor_reading}`;

            this.calendarOptions.events.push({
              title: decoder(oxygenStr),
              date: dateStr,
              backgroundColor: '#b8860b',
              borderColor: '#b8860b'
            });
          }
        }

        if (this.filterOptions.flavor.display) {
          for (const flavor of pond.flavorHistory) {
            const dateStr = date.formatDate(flavor.date * 1000, 'YYYY-MM-DD');
            const flavorStr = `Flavor: ${pond.name} - ${flavor.flavor_name}
              Severity: ${flavor.severity} - ${flavor.processor}`;

            this.calendarOptions.events.push({
              title: decoder(flavorStr),
              date: dateStr,
              backgroundColor: '#b34700',
              borderColor: '#b34700'
            });
          }
        }
      }

      // Add in all the rest of the feedings
      // This will go back 10 years
      // We do want to overwrite the feedings that are
      // already in the calendar here

      for (const [key, val] of Object.entries(
        store.state.dataQueries.allFeedings
      )) {
        feedingEvents.set(key, val);
      }

      if (this.filterOptions.feed.display) {
        for (const [dateStr, pounds] of feedingEvents) {
          this.calendarOptions.events.push({
            title: `Feed: ${numberWithCommas(pounds)} lbs`,
            date: dateStr,
            backgroundColor: '#a67b5b',
            borderColor: '#a67b5b'
          });
        }
      }
    },
    setEventFilter(key) {
      if (key === 'allEvents') {
        for (const option of Object.values(this.filterOptions)) {
          option.display = this.filterOptions.allEvents.display;
        }
      } else {
        if (this.filterOptions[key].display === false) {
          this.filterOptions.allEvents.display = false;
        }
      }

      this.setEvents();
    }
  },
  watch: {
    '$store.state.farm.ponds': {
      handler() {
        this.setEvents();
      },
      deep: true
    },
    '$store.state.dataQueries.allFeedings': {
      handler() {
        this.setEvents();
      },
      deep: true
    }
  }
};
</script>

<style>
.fc-button.fc-button-primary {
  color: #fff;
  background: #245ad3;
  border-color: #517ee1;
}

.fc-button.fc-button-primary:hover {
  color: #fff;
  background: #517ee1;
  border-color: #517ee1;
}

.fc-button.fc-button-primary:focus {
  box-shadow: none !important;
}

.fc-today-button.fc-button.fc-button-primary {
  color: #fff;
  background: #245ad3;
  border-color: #245ad3;
}

.fc-today-button.fc-button.fc-button-primary:active {
  color: #fff;
  background: #245ad3;
  border-color: #245ad3;
}

.fc-prev-button.fc-button.fc-button-primary:active {
  color: #fff;
  background: #245ad3;
  border-color: #245ad3;
}

.fc-filterButton-button.fc-button.fc-button-primary:active {
  color: #fff;
  background: #245ad3;
  border-color: #245ad3;
}

.fc-noteButton-button.fc-button.fc-button-primary:active {
  color: #fff;
  background: #245ad3;
  border-color: #245ad3;
}

.fc-next-button.fc-button.fc-button-primary:active {
  color: #fff;
  background: #245ad3;
  border-color: #245ad3;
}

.fc-dayGridMonth-button.fc-button.fc-button-primary.fc-button-active {
  color: #fff;
  background: #245ad3;
  border-color: #517ee1;
}

.fc-dayGridWeek-button.fc-button.fc-button-primary.fc-button-active {
  color: #fff;
  background: #245ad3;
  border-color: #517ee1;
}

.fc-dayGridMonth-button.fc-button.fc-button-primary:active {
  color: #fff;
  background: #245ad3;
  border-color: #517ee1;
}

.fc-dayGridWeek-button.fc-button.fc-button-primary:active {
  color: #fff;
  background: #245ad3;
  border-color: #517ee1;
}

@media (max-width: 700px) {
  .fc .fc-toolbar.fc-header-toolbar {
    display: block;
    text-align: center;
  }

  .fc-header-toolbar .fc-toolbar-chunk {
    display: block;
  }
}

.fc-event {
  font-size: 12px;
  font-weight: 400;
  line-height: 1.4 !important;
  white-space: pre-line !important;
}

.fc-sticky {
  padding: 0 0 0 3px !important;
}
</style>
