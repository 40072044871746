<template>
  <div>
    <div>
      <div class="col row items-center" style="padding: 10px 0 10px 0">
        <div class="col-7 cursor-pointer" @click="dialogInfo = true">
          <div class="row items-center">
            <div class="col q-px-sm">
              <span
                style="border-bottom: 1px solid black"
                class="text-subtitle1"
              >
                {{ pond.name }}
              </span>
            </div>
            <div class="col-7 row items-center text-caption">
              <div v-if="LastFlavor" class="col-6 q-pl-sm ellipsis-2-lines">
                {{ LastFlavor.flavor_name }}
                - {{ LastFlavor.severity }}
              </div>

              <div v-else class="col-6 text-grey-5 q-pl-sm">
                <div>- - -</div>
              </div>

              <div v-if="CurrentFlavor" class="col-6 q-pl-sm ellipsis-2-lines">
                {{ CurrentFlavor.flavor_name }}
                - {{ CurrentFlavor.severity }}
              </div>

              <div v-else class="col-6 text-grey-5 q-pl-sm">
                <div>- - -</div>
              </div>
            </div>
          </div>
        </div>

        <div class="col row items-center">
          <q-btn
            unelevated
            color="grey-4"
            textColor="black"
            padding="10px 0px"
            class="full-width"
            size="md"
            @click="flavorPond(pond)"
            @click.stop
          >
            <div class="row items-center justify-around full-width">
              <div style="width: 30px"></div>
              <div>Flavor</div>
              <div v-if="CurrentFlavor" style="width: 30px; height: 24px">
                <q-icon name="task_alt" color="primary" />
              </div>
              <div v-else style="width: 30px; height: 24px"></div>
            </div>
          </q-btn>
        </div>
      </div>
    </div>

    <!-- Info Dialog -->
    <q-dialog position="top" full-hight v-model="dialogInfo">
      <q-card style="width: 500px">
        <div class="row items-center justify-between q-pa-xs">
          <div style="width: 44px"></div>
          <div class="col text-h6 text-center">
            {{ pond.name }}
          </div>
          <div>
            <q-btn
              unelevated
              icon="close"
              color="grey-3"
              text-color="black"
              size="md"
              padding="2px 10px"
              v-close-popup
            />
          </div>
        </div>
        <q-separator />

        <div class="row text-subtitle2">
          <div class="col-auto text-center" style="width: 76px">Date</div>
          <div class="col text-center">Processor</div>
          <div class="col text-center">Flavor</div>
          <div class="col-auto text-center">Severity</div>
          <div class="col-auto text-center" style="width: 50px">Note</div>
          <div class="col-auto" style="width: 52px"></div>
        </div>
        <q-separator />

        <div v-for="(flavor, i) in LastTenEntries" :key="flavor.id">
          <div class="row text-body2 q-px-xs">
            <div
              class="col-auto q-pl-xs"
              style="width: 72px; padding: 12px 0 12px 0"
            >
              {{ parseTimeStamp(flavor.date, 'short') }}
            </div>

            <div class="col text-center" style="padding-top: 12px">
              {{ decoder(flavor.processor) }}
            </div>

            <div class="col text-center" style="padding-top: 12px">
              <span>
                {{ flavor.flavor_name }}
              </span>
            </div>

            <div
              class="col-auto text-center"
              style="padding-top: 12px; width: 53px"
            >
              {{ flavor.severity }}
            </div>

            <div class="col-auto row items-center q-px-sm" style="width: 58px">
              <q-btn
                v-if="flavor.memo"
                unelevated
                icon="sticky_note_2"
                color="grey-3"
                text-color="black"
                size="md"
                padding="2px 10px"
              >
                <q-menu
                  transition-show="flip-right"
                  transition-hide="flip-left"
                  anchor="bottom right"
                  self="top right"
                >
                  <q-card
                    style="min-width: 50px; max-width: 300px"
                    class="q-pa-sm"
                  >
                    <div class="text-center">
                      {{ flavor.memo }}
                    </div>
                  </q-card>
                </q-menu>
              </q-btn>
            </div>

            <div class="col-auto row items-center">
              <q-btn
                unelevated
                icon="edit"
                color="grey-3"
                text-color="accent"
                padding="2px 10px"
                @click="editFlavor(flavor)"
              />
            </div>
          </div>
          <q-separator v-if="i < 9" color="grey-2" />
        </div>
      </q-card>
    </q-dialog>

    <!-- Flavor Pond Dialog -->
    <q-dialog persistent full-height position="top" v-model="dialogFlavorPond">
      <q-card style="width: 450px">
        <div class="text-h6 text-center q-py-sm">
          {{ pond.name }}
        </div>
        <q-separator />

        <div class="scroll" style="max-height: calc(100vh - 80px)">
          <q-form @submit="submitFlavor()">
            <div class="q-px-lg q-pt-sm q-pb-sm">
              <div class="q-pb-sm">
                <q-input
                  outlined
                  dense
                  label="Date"
                  v-model="flavor.date"
                  lazy-rules
                  :rules="[(val) => isDateValid(val) || 'Invalid Date']"
                  hide-bottom-space
                >
                  <template v-slot:append>
                    <q-icon name="event" class="cursor-pointer" color="primary">
                      <q-popup-proxy
                        ref="qDateProxy"
                        transition-show="scale"
                        transition-hide="scale"
                      >
                        <q-date v-model="flavor.date" mask="MM/DD/YYYY">
                          <div class="row items-center justify-end">
                            <q-btn
                              v-close-popup
                              label="Close"
                              color="primary"
                              flat
                            />
                          </div>
                        </q-date>
                      </q-popup-proxy>
                    </q-icon>
                  </template>
                </q-input>
              </div>

              <div class="q-pb-sm">
                <q-select
                  v-model="flavor.proc_id"
                  label="Select Processor"
                  dense
                  outlined
                  :options="ProcessorOptions"
                  emit-value
                  map-options
                  :disable="action === 'edit'"
                  lazy-rules
                  hide-bottom-space
                  :rules="[(val) => !!val || 'Please Select A Processor']"
                />
              </div>

              <div class="q-pb-sm">
                <q-select
                  v-model="flavor.flavor"
                  label="Select Flavor"
                  dense
                  outlined
                  :options="FlavorOptions"
                  emit-value
                  map-options
                  lazy-rules
                  hide-bottom-space
                  :rules="[(val) => !!val || 'Please Select A Flavor']"
                />
              </div>

              <div>
                <q-input
                  outlined
                  dense
                  autogrow
                  v-model="flavor.memo"
                  label="Note"
                />
              </div>
            </div>

            <div class="q-pt-xs q-px-lg">
              <div style="padding: 4px 12px 0 12px">
                <q-slider
                  dense
                  v-model="flavor.severity"
                  :min="0"
                  :max="5"
                  markers
                  :step="1"
                  snap
                  color="accent"
                  track-size="10px"
                  thumb-size="30px"
                />
              </div>
              <div class="row justify-center text-subtitle1 q-pb-xs">
                Severity - {{ flavor.severity }}
              </div>
            </div>
            <q-separator />

            <div class="row q-py-lg q-px-lg">
              <div class="col q-pr-sm">
                <q-btn
                  rounded
                  label="Cancel"
                  color="grey-4"
                  text-color="black"
                  size="md"
                  padding="10px md"
                  class="full-width"
                  v-close-popup
                />
              </div>
              <div v-if="action === 'update'" class="col q-px-sm">
                <q-btn
                  rounded
                  label="Delete"
                  color="grey-4"
                  text-color="accent"
                  size="md"
                  padding="10px md"
                  class="full-width"
                  @click="deleteFlavor"
                />
              </div>
              <div class="col q-pl-sm">
                <q-btn
                  rounded
                  label="Submit"
                  color="secondary"
                  text-color="black"
                  size="md"
                  padding="10px md"
                  class="full-width"
                  type="submit"
                />
              </div>
            </div>
          </q-form>
        </div>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import { decoder, numberWithCommas, isNumValid, cloneObj } from '@/lib/helpers';
import {
  createTransactionObj,
  getUID,
  guidMatchHelper
} from '@/store/transactionHelpers';
import { parseTimeStamp, isDateValid, setTimeOfDay } from '@/lib/date-utils.js';
import { date } from 'quasar';
import store from '@/store';

function defaultFlavor() {
  return {
    id: null,
    user_id: store.state.user.id,
    proc_id: null,
    flavor: null,
    severity: 0,
    date: date.formatDate(new Date(), 'MM/DD/YYYY'),
    memo: '',
    guid: null,
    storeInfo: {
      farmId: store.state.selectedFarm.farm_id
    }
  };
}

export default {
  name: 'FlavorPondRow',
  props: {
    pond: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      action: 'create',
      dateCopy: null,
      dateCopyStr: null,
      dialogFlavorPond: false,
      dialogInfo: false,
      decoder,
      isDateValid,
      numberWithCommas,
      parseTimeStamp,
      poundsCopy: null,
      qtyErr: false,
      flavor: defaultFlavor()
    };
  },
  // mounted() {},
  methods: {
    deleteFlavor() {
      this.$q
        .dialog({
          title: 'Confirm Delete',
          message: 'Are you sure you want to delete this flavor?',
          ok: {
            icon: 'delete',
            label: 'Delete',
            color: 'accent'
          },
          cancel: {
            label: 'Cancel',
            color: 'grey-3',
            textColor: 'primary'
          },
          focus: 'none',
          persistent: true
        })
        .onOk(() => {
          this.action = 'delete';
          this.submitFlavor();
        });
    },
    editFlavor(flavor) {
      this.action = 'update';

      this.flavor = {
        ...defaultFlavor(),
        ...flavor
      };

      this.dateCopy = this.flavor.date;
      this.flavor.date = date.formatDate(
        new Date(flavor.date * 1000),
        'MM/DD/YYYY'
      );
      this.dateCopyStr = this.flavor.date;

      this.dialogFlavorPond = true;
    },
    flavorPond(pond) {
      this.action = 'create';
      this.flavor = defaultFlavor();
      this.dialogFlavorPond = true;
    },
    submitFlavor() {
      const flavor = cloneObj(this.flavor);

      const dateStr = this.flavor.date;
      flavor.date = setTimeOfDay(dateStr);

      if (
        (this.action === 'update' || this.action === 'delete') &&
        this.dateCopyStr === dateStr
      ) {
        flavor.date = this.dateCopy;
      }

      if (this.action === 'create') {
        flavor.id = 0;
      }

      const flavorObj = this.FlavorOptions.find(
        (x) => x.value === flavor.flavor
      );

      const procObj = this.ProcessorOptions.find(
        (x) => x.value === flavor.proc_id
      );

      flavor.guid = getUID();
      flavor.user_id = store.state.user.user_id;
      flavor.flavor_name = flavorObj.name;
      flavor.processor = procObj.name;
      flavor.storeInfo.action = this.action + 'FlavorPond';
      flavor.storeInfo.category = 'flavorPond';
      flavor.storeInfo.pondId = this.pond.id;

      guidMatchHelper(flavor, this.action);

      store.dispatch('publish', flavor);

      this.dialogFlavorPond = false;

      this.$q.notify({
        message: `Entry ${
          this.action === 'create'
            ? 'Added'
            : this.action === 'update'
            ? 'Updated'
            : 'Deleted'
        }`,
        icon: 'check',
        color: this.action === 'delete' ? 'negative' : 'primary'
      });
    }
  },
  computed: {
    CurrentFlavor() {
      const currDate = date.formatDate(new Date(), 'YYYY-MM-DD');

      // if no initial flavor, return null
      if (this.pond.flavorHistory.length === 0) {
        return null;
      }

      // find the most recent flavor
      const currFlavor = this.pond.flavorHistory.reduce((a, b) => {
        if (a.date > b.date) {
          return a;
        }
        return b;
      });

      const currFlavorDate = date.formatDate(
        currFlavor.date * 1000,
        'YYYY-MM-DD'
      );

      return currDate === currFlavorDate ? currFlavor : null;
    },
    LastTenEntries() {
      return this.pond.flavorHistory.slice(0, 10);
    },
    LastFlavor() {
      const currDate = date.formatDate(new Date(), 'YYYY-MM-DD');
      let lastFlavor = null;

      let lastDate = null;
      let continued = false;
      for (let i = 0; i < this.pond.flavorHistory.length; i++) {
        const flavor = this.pond.flavorHistory[i];

        const flavorDate = date.formatDate(
          new Date(flavor.date * 1000),
          'YYYY-MM-DD'
        );

        if (flavorDate === currDate && !continued) {
          continued = true;
          continue;
        } else {
          lastFlavor = cloneObj(flavor);

          break;
        }
      }

      return lastFlavor;
    },
    FlavorAmount() {
      let amount = parseFloat(this.flavor.flavor);

      const decimalCount = this.flavor.flavor.split('.').length - 1;

      if (this.treatment.flavor.endsWith('.') && decimalCount === 1) {
        amount += '.';
      }

      if (this.treatment.flavor === '.') {
        return '0.';
      }

      return numberWithCommas(amount);
    },
    FlavorOptions() {
      const flavors = [];
      for (const flavor of store.state.farm.flavors) {
        flavors.push({
          value: flavor.id,
          name: flavor.name,
          label: flavor.name
        });
      }

      // Old feedx data has flavor IDs that
      // are from other farms
      if (this.dialogFlavor) {
        const hasId = flavors.some((x) => x.value === this.flavor.flavor);

        if (!hasId) {
          flavors.push({
            value: this.flavor.flavor,
            name: this.flavor.flavor_name,
            label: this.flavor.flavor_name
          });
        }
      }

      return flavors;
    },
    ProcessorOptions() {
      const processors = [];
      for (const processor of store.state.farm.processors) {
        processors.push({
          value: processor.id,
          name: processor.name,
          label: processor.name
        });
      }

      // Old feedx data has flavor IDs that
      // are from other farms
      if (this.dialogFlavor) {
        const hasId = processors.some((x) => x.value === this.flavor.proc_id);

        if (!hasId) {
          processors.push({
            value: this.flavor.proc_id,
            name: this.flavor.processor,
            label: this.flavor.processor
          });
        }
      }

      return processors;
    }
  }
};
</script>

<style></style>
