<template>
  <div>
    <q-resize-observer @resize="onResize" />
    <div id="adjustable" class="row justify-between items-center no-wrap">
      <div class="q-pa-sm" :style="$q.screen.gt.xs && 'width: 203px'">
        <q-btn
          icon="arrow_back"
          :label="$q.screen.gt.xs ? 'Overview' : ''"
          color="primary"
          size="md"
          @click="$emit('back')"
        />
      </div>

      <div class="row q-pa-sm">
        <div class="gt-sm">
          <q-btn
            flat
            label="Stock"
            size="md"
            class="q-mr-sm"
            @click="addEntry('Stock')"
          />

          <q-btn
            flat
            label="Harvest"
            size="md"
            class="q-mr-sm"
            @click="addEntry('Harvest')"
          />

          <q-btn
            flat
            label="Transfer"
            size="md"
            class="q-mr-sm"
            @click="addEntry('Transfer')"
          />

          <q-btn
            flat
            label="Loss"
            size="md"
            class="q-mr-sm"
            @click="addEntry('Loss')"
          />

          <q-btn
            flat
            label="Reconcile"
            size="md"
            @click="addEntry('Reconcile')"
          />
        </div>

        <q-btn-dropdown
          unelevated
          color="primary"
          label="Fish In / Out"
          anchor="bottom right"
          self="top right"
          class="lt-sm"
          :menu-offset="[0, 0]"
        >
          <q-list style="min-width: 145px">
            <q-item clickable v-close-popup @click="addEntry('Stock')">
              <q-item-section>Stock</q-item-section>
            </q-item>
            <q-item clickable v-close-popup @click="addEntry('Harvest')">
              <q-item-section>Harvest</q-item-section>
            </q-item>
            <q-item clickable v-close-popup @click="addEntry('Transfer')">
              <q-item-section>Transfer</q-item-section>
            </q-item>
            <q-item clickable v-close-popup @click="addEntry('Loss')">
              <q-item-section>Loss</q-item-section>
            </q-item>
            <q-item clickable v-close-popup @click="addEntry('Reconcile')">
              <q-item-section>Reconcile</q-item-section>
            </q-item>
          </q-list>
        </q-btn-dropdown>
      </div>
    </div>
    <q-separator />

    <div class="scroll" :style="`height: ${useableHeight}px`">
      <div class="bg-grey-1 shadow-2">
        <div class="q-px-sm">
          <div class="row q-py-sm">
            <div class="col-12 col-sm-6 col-md-4 q-pa-sm">
              <div>
                <q-card flat bordered class="q-px-md q-py-sm">
                  <div class="row items-center">
                    <q-btn
                      flat
                      round
                      dense
                      icon="chevron_left"
                      padding="2px"
                      :disabled="!goToPond.previous"
                      @click="$emit('goToPrevious')"
                    />

                    <div class="col text-h6 text-center">
                      {{ pond.name }}
                    </div>

                    <q-btn
                      flat
                      round
                      dense
                      icon="chevron_right"
                      padding="2px"
                      :disabled="!goToPond.next"
                      @click="$emit('goToNext')"
                    />
                  </div>
                  <q-separator />

                  <div class="text-subtitle1 q-py-sm">
                    <div class="row justify-between">
                      <div>Head</div>
                      <div>
                        {{ PondInfo.head.toLocaleString() }}
                      </div>
                    </div>
                  </div>
                  <q-separator />

                  <div class="text-subtitle1 q-py-sm">
                    <div class="row justify-between">
                      <div>Estimated Weight</div>
                      <div>
                        {{ PondInfo.weight.toLocaleString() }}
                      </div>
                    </div>
                  </div>
                  <q-separator />
                </q-card>
              </div>
            </div>

            <div
              v-for="group of ActiveGroups"
              :key="group.stocking_id"
              class="col-12 col-sm-6 col-md-4 q-pa-sm"
            >
              <q-card flat bordered class="q-px-md q-pt-md q-pb-sm">
                <div class="row flex-center text-black q-pb-sm">
                  <div
                    class="row flex-center rounded-borders"
                    :class="'bg-' + group.color"
                    style="width: 50px; height: 25px"
                  >
                    {{ group.index }}
                  </div>
                  <div class="q-pl-sm text-subtitle2">
                    {{ group.name }}
                  </div>
                </div>
                <div class="q-pb-xs"></div>
                <q-separator />

                <div>
                  <div class="row justify-between q-pt-xs">
                    <div>Size</div>
                    <div>{{ group.size.toLocaleString() }}</div>
                  </div>
                  <q-separator />
                </div>

                <div>
                  <div class="row justify-between q-pt-xs">
                    <div>Head</div>
                    <div>{{ group.head.toLocaleString() }}</div>
                  </div>
                  <q-separator />
                </div>

                <div>
                  <div class="row justify-between q-pt-xs">
                    <div>Estimated Weight</div>
                    <div>{{ group.weight.toLocaleString() }}</div>
                  </div>
                  <q-separator />
                </div>
              </q-card>
            </div>
          </div>
        </div>

        <q-separator />
        <div class="row text-subtitle2" :class="$q.screen.gt.xs && 'q-px-sm'">
          <div class="text-center" style="width: 75px">Date</div>
          <div class="col text-center">Type</div>
          <div class="col text-center">From/To</div>
          <div class="col text-center">Head</div>
          <div class="lt-md col-auto text-center" style="width: 22px"></div>
          <div class="gt-sm col text-center">Weight</div>
          <div class="gt-sm col text-center">Total Head</div>
          <div class="gt-sm col text-center">Group I/O</div>
          <div class="gt-sm col text-center">Active</div>
          <div class="gt-sm text-center" style="width: 44px">Note</div>
          <div class="gt-sm text-center" style="width: 52px">Attach</div>
          <div class="gt-sm" style="width: 44px"></div>
          <div class="lt-md" style="width: 36px"></div>
        </div>
      </div>

      <div
        v-if="History.length < 1"
        class="text-center text-h5 text-grey-6 q-px-md q-py-lg"
      >
        No Entries For This Pond
      </div>

      <div class="q-pb-lg">
        <div v-for="history in History" :key="history.fishmove_id">
          <div
            class="row items-center"
            :class="$q.screen.gt.sm ? 'q-py-sm' : 'q-py-md'"
          >
            <div class="text-center" style="width: 75px">
              {{ parseTimeStamp(history.date, 'short') }}
            </div>

            <div class="col text-center">
              {{ history.transfer_type }}
            </div>

            <div class="col text-center">
              {{ decoder(history.transfer_name) }}
            </div>

            <div
              class="col text-center"
              :class="history.headIn ? 'text-green-8' : 'text-red-8'"
            >
              {{ history.head.toLocaleString() }}
            </div>

            <div class="gt-sm col text-center">
              {{ history.weight.toLocaleString() }}
            </div>

            <div class="gt-sm col text-center">
              {{ history.totalHead.toLocaleString() }}
            </div>

            <div class="lt-md flex justify-center" style="width: 22px">
              <div class="column reverse">
                <div
                  v-for="group in history.activeGroups"
                  :key="'active' + group.id"
                >
                  <div
                    class="rounded-borders"
                    :class="`bg-${group.color}`"
                    style="width: 12px; height: 12px; margin: 1px"
                  ></div>
                </div>
              </div>
            </div>

            <div class="gt-sm col row justify-center">
              <div v-for="group in history.groupIO" :key="'IO' + group.id">
                <div
                  class="row flex-center rounded-borders"
                  :class="`bg-${group.color}`"
                  style="padding: 1px 5px 1px 5px; margin: 1px"
                >
                  {{ group.index }}
                </div>
              </div>
            </div>

            <div class="gt-sm col row justify-center">
              <div
                v-for="group in history.activeGroups"
                :key="'active' + group.id"
              >
                <div
                  class="row flex-center rounded-borders"
                  :class="`bg-${group.color}`"
                  style="padding: 1px 5px 1px 5px; margin: 1px"
                >
                  {{ group.index }}
                </div>
              </div>
            </div>

            <div
              v-if="history.memo && history.memo !== ''"
              class="gt-sm q-pl-xs"
            >
              <q-btn
                unelevated
                icon="sticky_note_2"
                color="grey-3"
                text-color="black"
                size="md"
                padding="2px 10px"
                @click="displayNote(history)"
              />
            </div>
            <div v-else style="width: 48px" class="gt-sm"></div>

            <div class="gt-sm q-px-xs">
              <q-btn
                unelevated
                :icon="history.attachments.length > 0 ? 'image' : 'add'"
                color="grey-3"
                text-color="primary"
                size="md"
                padding="2px 10px"
                @click="viewAttachments(history)"
              />
            </div>

            <div
              v-if="history.transfer_type !== 'Transfer In'"
              class="gt-sm q-pr-sm"
            >
              <q-btn
                unelevated
                icon="edit"
                color="grey-3"
                text-color="accent"
                padding="2px 10px"
                @click="editEntry(history)"
              />
            </div>

            <div
              v-if="history.transfer_type === 'Transfer In'"
              class="gt-sm q-pr-sm"
            >
              <q-btn
                unelevated
                icon="lock"
                color="grey-3"
                text-color="accent"
                padding="2px 10px"
                @click="displayTransferNotice(history)"
              />
            </div>

            <div class="lt-md q-px-sm">
              <q-btn
                unelevated
                focused="true"
                icon="more_vert"
                color="grey-3"
                text-color="black"
                padding="1px 0px"
                @click="displayHistoryItem(history)"
              >
              </q-btn>
            </div>
          </div>
          <q-separator />
        </div>
      </div>
    </div>

    <!-- Line Entry Dialog -->
    <q-dialog position="right" full-hight v-model="dialogHistory">
      <q-card style="width: min(400px, 90vw)">
        <div class="row items-center q-pa-sm">
          <div class="col">
            <div class="text-center text-subtitle1">
              {{ parseTimeStamp(history?.date || '', 'short') }}
            </div>
          </div>
        </div>
        <q-separator style="padding-top 1px" />

        <div>
          <div class="row justify-between q-py-sm q-px-md">
            <div>Type</div>
            <div>
              {{ history.transfer_type ?? '' }}
            </div>
          </div>
          <q-separator />

          <div class="row justify-between q-py-sm q-px-md">
            <div>From/To</div>
            <div>
              {{ decoder(history.transfer_name) ?? '' }}
            </div>
          </div>
          <q-separator />

          <div class="row justify-between q-py-sm q-px-md">
            <div>Head</div>
            <div :class="history.headIn ? 'text-green-8' : 'text-red-8'">
              {{ history.head?.toLocaleString() ?? '' }}
            </div>
          </div>
          <q-separator />

          <div class="row justify-between q-py-sm q-px-md">
            <div>Weight</div>
            <div>
              {{ history.weight?.toLocaleString() ?? '' }}
            </div>
          </div>
          <q-separator />

          <div class="row justify-between q-py-sm q-px-md">
            <div>Total Head</div>
            <div>
              {{ history.totalHead?.toLocaleString() ?? '' }}
            </div>
          </div>
          <q-separator />

          <div class="row items-center justify-between q-py-sm q-px-md">
            <div>Group I/O</div>
            <div class="row">
              <div v-for="group in history.groupIO" :key="'IO' + group.id">
                <div
                  class="row flex-center rounded-borders"
                  :class="`bg-${group.color}`"
                  style="padding: 1px 5px 1px 5px; margin: 1px"
                >
                  {{ group.index }}
                </div>
              </div>
            </div>
          </div>
          <q-separator />

          <div class="row items-center justify-between q-py-sm q-px-md">
            <div>Active</div>
            <div class="row">
              <div
                v-for="group in history.activeGroups"
                :key="'active' + group.id"
              >
                <div
                  class="row flex-center rounded-borders"
                  :class="`bg-${group.color}`"
                  style="padding: 1px 5px 1px 5px; margin: 1px"
                >
                  {{ group.index }}
                </div>
              </div>
            </div>
          </div>
          <q-separator />

          <div class="row items-center justify-between q-py-sm q-px-md">
            <div class="q-pr-sm" style="padding-bottom: 2px">Attachments</div>
            <div>
              <q-btn
                unelevated
                :icon="
                  (history?.attachments?.length ?? 0) > 0 ? 'image' : 'add'
                "
                color="grey-3"
                text-color="primary"
                size="md"
                padding="2px 10px"
                class="q-ml-sm"
                @click="viewAttachments(history)"
              />
            </div>
          </div>
          <q-separator />

          <div class="row items-center q-py-sm q-px-md">
            <div class="text-weight-medium q-pr-sm" style="padding-bottom: 2px">
              Note:
            </div>
            <div>
              {{ decoder(history.memo) }}
            </div>
          </div>
          <q-separator />

          <div class="row justify-end q-pa-md">
            <q-btn
              unelevated
              label="Close"
              color="grey-3"
              text-color="black"
              class="q-mr-sm"
              v-close-popup
            />

            <div v-if="history.transfer_type !== 'Transfer In'">
              <q-btn
                unelevated
                label="Edit"
                color="grey-3"
                text-color="accent"
                @click="editEntry(history)"
              />
            </div>

            <div v-if="history.transfer_type === 'Transfer In'">
              <q-btn
                unelevated
                icon="lock"
                color="grey-4"
                text-color="accent"
                @click="displayTransferNotice(history)"
              />
            </div>
          </div>
        </div>
      </q-card>
    </q-dialog>

    <!-- Fish Move Dialog -->
    <q-dialog persistent position="top" v-model="dialogFishMove">
      <q-card style="width: 450px">
        <div class="row items-center q-py-sm q-px-md">
          <div class="col">
            <div class="text-center text-subtitle1">
              {{ pond.name }} - {{ IOtype }}
            </div>
          </div>
        </div>
        <q-separator color="black" style="padding-top 1px" />

        <q-form @submit="submitEntry" class="q-px-md q-pb-md q-pt-sm">
          <div v-if="action === 'update' && IOtype === 'Loss'" class="q-py-sm">
            <div v-if="lossGroup">
              <div class="row items-center">
                <div
                  class="row flex-center rounded-borders"
                  :class="'bg-' + lossGroup.color"
                  style="width: 25px; height: 25px"
                >
                  {{ lossGroup.label }}
                </div>
                <div class="q-pl-sm">
                  {{ lossGroup.stocked }}
                </div>
                <div class="q-pl-md">
                  <q-btn
                    flat
                    dense
                    icon="info"
                    color="primary"
                    size="md"
                    padding="4px"
                    @click="dialogInfo = true"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="q-py-sm">
            <q-input
              filled
              dense
              label="Date"
              v-model="fishMove.date"
              lazy-rules
              :rules="[(val) => isDateValid(val) || 'Invalid Date']"
              hide-bottom-space
            >
              <template v-slot:append>
                <q-icon name="event" class="cursor-pointer" color="primary">
                  <q-popup-proxy
                    ref="qDateProxy"
                    transition-show="scale"
                    transition-hide="scale"
                  >
                    <q-date v-model="fishMove.date" mask="MM/DD/YYYY">
                      <div class="row items-center justify-end">
                        <q-btn
                          v-close-popup
                          label="Close"
                          color="primary"
                          flat
                        />
                      </div>
                    </q-date>
                  </q-popup-proxy>
                </q-icon>
              </template>
            </q-input>
          </div>

          <div v-if="IOtype === 'Stock'" class="q-pb-sm">
            <q-select
              filled
              dense
              v-model="hatchery"
              :options="Hatcheries"
              label="Hatchery"
              hide-bottom-space
              :rules="[(val) => val || 'Required!']"
            />
          </div>

          <div v-if="IOtype === 'Harvest'" class="q-pb-sm">
            <q-select
              filled
              dense
              v-model="processor"
              :options="Processors"
              label="Processor"
              hide-bottom-space
              :rules="[(val) => val || 'Required!']"
            />
          </div>

          <div v-if="IOtype === 'Transfer'" class="q-pb-sm">
            <q-select
              filled
              dense
              v-model="transferPond"
              :options="PondOptions"
              label="Pond"
              hide-bottom-space
              :rules="[(val) => val || 'Required!']"
            />
          </div>

          <div class="q-pb-sm">
            <q-input
              filled
              dense
              v-model.number="fishMove.head"
              label="Head"
              step="any"
              min="0"
              mask="#####"
              type="number"
              lazy-rules
              hide-bottom-space
              :rules="[(val) => isNumValid(val) || val > 0]"
            />
          </div>

          <div v-if="RequireWeight" class="q-pb-sm">
            <q-input
              filled
              dense
              v-model.number="fishMove.weight"
              label="Total Fish Weight"
              step="any"
              min="0"
              mask="#####"
              type="number"
              lazy-rules
              hide-bottom-space
              :rules="[(val) => isNumValid(val) || val > 0]"
            />
            <div v-if="IOtype === 'Loss'" class="text-caption text-center">
              (weight is required when date is changed)
            </div>
          </div>

          <div
            v-if="
              action !== 'update' && action !== 'delete' && IOtype === 'Loss'
            "
            class="q-pb-sm"
          >
            <q-select
              filled
              dense
              v-model="lossGroup"
              :options="ActiveOptions"
              label="Select Fish Group"
            >
              <template v-slot:option="scope">
                <q-item v-bind="scope.itemProps" v-on="scope.itemEvents">
                  <div class="row items-center">
                    <div
                      class="row flex-center rounded-borders"
                      :class="'bg-' + scope.opt.color"
                      style="width: 25px; height: 25px"
                    >
                      {{ scope.opt.label }}
                    </div>
                    <div class="q-pl-sm">
                      {{ scope.opt.stocked }}
                    </div>
                  </div>
                </q-item>
              </template>
              <template v-slot:selected>
                <div v-if="lossGroup" class="q-py-sm">
                  <div class="row items-center">
                    <div
                      class="row flex-center rounded-borders"
                      :class="'bg-' + lossGroup.color"
                      style="width: 25px; height: 25px"
                    >
                      {{ lossGroup.label }}
                    </div>
                    <div class="q-pl-sm">
                      {{ lossGroup.stocked }}
                    </div>
                  </div>
                </div>
              </template>
            </q-select>
          </div>

          <div class="q-pb-sm">
            <q-input
              filled
              dense
              autogrow
              v-model="fishMove.memo"
              label="Note"
            />
          </div>

          <div class="row justify-end q-pt-xs">
            <q-btn
              unelevated
              label="Cancel"
              color="grey-4"
              text-color="black"
              size="md"
              class="q-mr-sm"
              v-close-popup
            />
            <q-btn
              unelevated
              v-if="action === 'update'"
              label="Delete"
              color="grey-4"
              text-color="accent"
              size="md"
              class="q-mr-sm"
              @click="deleteEntry"
            />
            <q-btn
              unelevated
              :label="
                action === 'update' && !$q.screen.xs ? 'Submit Edit' : 'Submit'
              "
              color="primary"
              size="md"
              type="submit"
              :loading="submitSpinner"
            />
          </div>
        </q-form>
      </q-card>
    </q-dialog>

    <!-- Not Editable Dialog -->
    <q-dialog v-model="dialogNotEditable">
      <q-card style="width: 450px">
        <div class="q-pa-sm">
          <div class="text-center text-subtitle1">Read Only</div>
        </div>
        <q-separator />

        <div class="q-pa-md">
          This entry is read-only because there is a loss on this group AFTER
          this entry date, and there could be a conflict if this entry is
          edited. If entry must be edited, please delete the loss entry that
          comes after this entry in the timeline.
        </div>
        <q-separator />

        <div class="row justify-end q-pa-sm">
          <q-btn
            unelevated
            label="ok"
            color="grey-3"
            text-color="black"
            padding="sm lg"
            v-close-popup
          />
        </div>
      </q-card>
    </q-dialog>

    <!-- Info Dialog -->
    <q-dialog v-model="dialogInfo">
      <q-card style="width: 450px">
        <div class="q-pa-sm">
          <div class="text-center text-subtitle1">Group Info</div>
        </div>
        <q-separator />

        <div class="q-pa-md">
          Group selection cannot be changed on edit because of potential
          conflicts with other entries. If group selection must be changed,
          delete this entry and create a new one.
        </div>
        <q-separator />

        <div class="row justify-end q-pa-sm">
          <q-btn
            unelevated
            label="ok"
            color="grey-3"
            text-color="black"
            padding="sm lg"
            v-close-popup
          />
        </div>
      </q-card>
    </q-dialog>

    <!-- Transfer Notice Dialog -->
    <q-dialog v-model="dialogTransferNotice">
      <q-card style="width: 450px">
        <div class="q-pa-md text-subtitle1">
          This entry can be edited at: <br />
          {{ transferNoticeName }}
        </div>
        <q-separator />

        <div class="row justify-end q-pa-sm">
          <q-btn
            unelevated
            label="ok"
            color="grey-3"
            text-color="black"
            padding="sm lg"
            v-close-popup
          />
        </div>
      </q-card>
    </q-dialog>

    <!-- Notes Dialog -->
    <q-dialog position="right" full-hight v-model="dialogNote">
      <q-card style="width: 300px">
        <div class="row items-center q-pa-sm">
          <div class="col">
            <div class="text-center text-subtitle1">
              {{ parseTimeStamp(history?.date || '', 'short') }}
            </div>
          </div>
          <q-btn
            unelevated
            icon="close"
            color="grey-3"
            text-color="black"
            size="md"
            padding="2px 10px"
            v-close-popup
          />
        </div>
        <q-separator style="padding-top 1px" />

        <div class="q-pa-sm">
          <span class="text-subtitle2 q-pr-sm"> Note: </span>
          {{ decoder(history.memo) }}
        </div>
      </q-card>
    </q-dialog>

    <!-- attachment display -->
    <Attachments
      v-if="displayAttachments"
      :fishmoveId="history.fishmove_id"
      :pondId="pond.id"
      :parentObj="history"
      :parentType="'fishmove'"
      @closeAttachments="displayAttachments = false"
    />
  </div>
</template>

<script>
import Attachments from '@/components/general/Attachments.vue';

import { fishGroupColors } from '@/lib/FishGroupColors.js';
import { parseTimeStamp } from '@/lib/date-utils.js';
import { createTransactionObj, getUID } from '@/store/transactionHelpers';

import store from '@/store';
import {
  decoder,
  numberWithCommas,
  isNumValid,
  cloneObj,
  getPercentage,
  getScrollbarWidth
} from '@/lib/helpers';
import { isDateValid, setTimeOfDay } from '@/lib/date-utils.js';
import { date, uid } from 'quasar';

const defaultFishMove = (fishId) => {
  return {
    id: null,
    from_account: fishId,
    to_account: null,
    head: null,
    weight: null,
    date: date.formatDate(new Date(), 'MM/DD/YYYY'),
    user_id: store.state.user.id,
    memo: '',
    preferred_stocking_id: null,
    preferred_weight: null,
    translock_id: null,
    harvest_link_id: null,
    has_children: null,
    date_entered: null,
    date_modified: null,
    guid: null,
    storeInfo: {
      farmId: store.state.selectedFarm.farm_id
    }
  };
};

export default {
  name: 'PondEntries',
  components: {
    Attachments
  },
  props: {
    pond: {
      type: Object,
      required: true
    },
    goToPond: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      fishGroupColors,
      action: 'create',
      btnWidth: 36,
      customPadding: 14,
      dateRangeSelection: 'prev90',
      decoder,
      dialogDates: false,
      dialogFishMove: false,
      dialogHistory: false,
      dialogInfo: false,
      dialogNote: false,
      dialogNotEditable: false,
      dialogTransferNotice: false,
      displayAttachments: false,
      dateCopy: null,
      dateCopyStr: null,
      dateSearch: false,
      fishMove: {},
      hatchery: null,
      headCopy: 0,
      history: {},
      IOtype: null,
      isDateValid,
      isNumValid,
      lossGroup: null,
      numberWithCommas,
      parseTimeStamp,
      poundsCopy: null,
      processor: null,
      stockingGroupId: null,
      store,
      submitSpinner: false,
      toggle: 'feedings',
      transferNoticeName: '',
      transferPond: null,
      transferPondIdCopy: null,
      useableHeight: 500
    };
  },
  // mounted() {},
  methods: {
    addEntry(type) {
      this.fishMove = defaultFishMove(this.pond.fish_id);

      this.fishMove.date = date.formatDate(new Date(), 'MM/DD/YYYY');
      this.dateCopyStr = this.fishMove.date;
      this.headCopy = 0;
      this.processor = null;
      this.hatchery = null;
      this.lossGroup = null;
      this.stockingGroupId = null;
      this.transferPond = null;
      this.action = 'create';
      this.IOtype = type;
      this.transferPondIdCopy = null;

      this.dialogFishMove = true;
    },
    deleteEntry() {
      this.$q
        .dialog({
          title: 'Confirm',
          message: 'Are you sure you want to delete this entry?',
          ok: {
            unelevated: true,
            color: 'accent',
            label: 'Delete',
            icon: 'delete'
          },
          cancel: {
            unelevated: true,
            label: 'Cancel',
            color: 'grey-4',
            textColor: 'black'
          },
          persistent: true,
          focus: 'none'
        })
        .onOk(() => {
          this.action = 'delete';
          this.submitEntry();
        });
    },
    displayHistoryItem(history) {
      this.history = history;
      this.dialogHistory = true;
    },
    displayNote(history) {
      this.history = history;
      this.dialogNote = true;
    },
    displayTransferNotice(entry) {
      this.transferNoticeName = entry.transfer_name;
      this.dialogTransferNotice = true;
    },
    editEntry(entry) {
      this.action = 'update';
      this.IOtype = entry.transfer_type.split(' ')[0];
      this.fishMove = defaultFishMove(this.pond.fish_id);

      this.fishMove.id = entry.fishmove_id;
      this.fishMove.weight = entry.weight;
      this.fishMove.head = entry.head;
      this.fishMove.activeGroups = cloneObj(entry.activeGroups);
      this.fishMove.memo = decoder(entry.memo);
      this.fishMove.storeInfo.from_account = entry.from_account;
      this.fishMove.storeInfo.to_account = entry.to_account;

      if (this.IOtype === 'Harvest') {
        for (const processor of this.Processors) {
          if (processor.fishId === entry.to_account) {
            this.processor = cloneObj(processor);
            break;
          }
        }
      }

      if (this.IOtype === 'Loss') {
        this.lossGroup = null;
        for (const group of this.FishGroups) {
          if (entry.groupIO.some((x) => x.id === group.stocking_id)) {
            this.lossGroup = {
              color: group.color,
              label: group.index,
              stocked: group.name,
              stockingId: group.stocking_id,
              value: group.stocking_id,
              head: group.head,
              weight: group.weight
            };
            break;
          }
        }
      }

      if (this.IOtype === 'Stock') {
        for (const hatchery of this.Hatcheries) {
          if (hatchery.fishId === entry.from_account) {
            this.hatchery = cloneObj(hatchery);
            break;
          }
        }
        this.stockingGroupId = entry.groupIO[0]?.id ?? null;
      }

      if (this.IOtype === 'Transfer') {
        for (const pond of this.PondOptions) {
          if (pond.fishId === entry.to_account) {
            this.transferPond = cloneObj(pond);
            this.transferPondIdCopy = pond.id;
            break;
          }
        }
      }

      this.dateCopy = entry.date;
      this.fishMove.date = date.formatDate(entry.date * 1000, 'MM/DD/YYYY');
      this.dateCopyStr = this.fishMove.date;
      this.headCopy = entry.head;

      this.dialogFishMove = true;
      this.dialogHistory = false;
    },
    onResize() {
      const adjustableHeight =
        document.getElementById('adjustable').clientHeight;

      const height =
        window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight;

      this.useableHeight = height - 53 - adjustableHeight;
    },
    async submitEntry() {
      const fishMove = cloneObj(this.fishMove);
      const transaction = createTransactionObj();

      const dateStr = this.fishMove.date;
      fishMove.date = setTimeOfDay(dateStr);

      if (
        (this.action === 'update' || this.action === 'delete') &&
        this.dateCopyStr === dateStr
      ) {
        fishMove.date = this.dateCopy;
      }

      if (this.action === 'create') {
        fishMove.id = 0;
      }

      fishMove.weight = this.fishMove.weight;

      if (this.IOtype === 'Loss') {
        fishMove.to_account = store.state.farm.settings?.loss?.fish_id ?? null;
        transaction.to_account =
          store.state.farm.settings?.loss?.grain_id ?? null;
        fishMove.preferred_stocking_id = this.lossGroup.stockingId;

        if (this.dateCopyStr === dateStr) {
          const fishSize = this.lossGroup.weight / this.lossGroup.head;
          fishMove.weight = fishSize * fishMove.head;
        }
      }

      if (this.IOtype === 'Harvest') {
        fishMove.to_account = this.processor.fishId;
        transaction.to_account = this.processor.grainId;
      }

      if (this.IOtype === 'Stock') {
        fishMove.from_account = this.hatchery.fishId;
        fishMove.to_account = this.pond.fish_id;
      }

      if (this.IOtype === 'Reconcile') {
        fishMove.to_account = null; // set on server
        transaction.to_account = null; // set on server
      }

      if (this.IOtype === 'Transfer') {
        fishMove.to_account = this.transferPond.fishId;
        transaction.to_account = null; // set on server
        fishMove.storeInfo.transferPondIds = [this.transferPond.id];
        if (
          this.transferPond.id !== this.transferPondIdCopy &&
          this.transferPondIdCopy
        ) {
          fishMove.storeInfo.transferPondIds.push(this.transferPondIdCopy);
        }
      }

      fishMove.storeInfo.action = this.action + 'Fish' + this.IOtype;

      fishMove.guid = getUID();
      fishMove.user_id = store.state.user.user_id;
      console.log(fishMove.memo.length);

      transaction.guid = getUID();
      transaction.from_account = this.pond.grain_id;
      transaction.pounds = 0; //fishMove.weight;
      transaction.purchaser = this.pond.purchaser;
      transaction.memo = fishMove.memo;
      fishMove.storeInfo.transaction = transaction;
      fishMove.storeInfo.pondId = this.pond.id;
      fishMove.storeInfo.type = this.IOtype;

      if (this.IOtype === 'Stock' && !this.submitStockValidation(fishMove)) {
        return;
      }

      if (this.IOtype === 'Loss' && !this.submitLossValidation(fishMove)) {
        return;
      }

      this.submitSpinner = true;

      const serverRes = await store.dispatch('publishFishMove', fishMove);

      this.dialogFishMove = false;

      if (serverRes) {
        this.submitSpinner = false;
        this.$q.notify({
          color: this.action === 'delete' ? 'negative' : 'primary',
          message: this.action === 'delete' ? 'Entry Deleted' : 'Entry Saved'
        });
      } else {
        this.submitSpinner = false;
        this.$q.notify({
          color: 'negative',
          message: 'Error Processing Entry'
        });
      }
    },
    submitStockValidation(fishMove) {
      // Check if editable
      for (const history of this.History) {
        if (
          history.date <= fishMove.date ||
          history.fishmove_id === fishMove.id
        ) {
          continue;
        }

        if (history.transfer_type === 'Loss') {
          for (const group of history.groupIO) {
            if (group.id === this.stockingGroupId) {
              this.dialogNotEditable = true;
              return false;
            }
          }
        }
      }

      return true;
    },
    submitLossValidation(fishMove) {
      // Check if editable
      for (const history of this.History) {
        if (
          history.date <= fishMove.date ||
          history.fishmove_id === fishMove.id
        ) {
          continue;
        }

        if (history.transfer_type === 'Loss') {
          for (const group of history.groupIO) {
            if (group.id === this.lossGroup.stockingId) {
              this.dialogNotEditable = true;
              return false;
            }
          }
        }
      }

      // Make sure date is after stocking date
      // AND total head does not exceed head currently in pond
      let totalHead = fishMove.head - this.headCopy;
      for (const group of this.FishGroups) {
        if (
          group.stocking_id === this.lossGroup.stockingId &&
          this.action !== 'delete'
        ) {
          if (group.date > fishMove.date) {
            this.$q.dialog({
              title: 'Error',
              message: 'Date must be after stocking date',
              ok: {
                unelevated: true,
                color: 'primary',
                label: 'Ok'
              },
              persistent: true,
              focus: 'none'
            });
            return false;
          }

          if (group.head < totalHead) {
            this.$q.dialog({
              title: 'Error',
              message: 'Head cannot exceed head currently in pond',
              ok: {
                unelevated: true,
                color: 'primary',
                label: 'Ok'
              },
              persistent: true,
              focus: 'none'
            });
            return false;
          }
        }
      }

      return true;
    },
    validatePrice(val) {
      return (val && isNumValid(val)) || !val;
    },
    viewAttachments(history) {
      this.history = history;
      this.displayAttachments = true;
    }
  },
  computed: {
    ActiveGroups() {
      return (
        this.FishGroups?.filter((group) => group.status === 'active') ?? []
      );
    },
    ActiveOptions() {
      if (this.action === 'update') {
        return [this.lossGroup];
      }

      return this.ActiveGroups.map((x) => {
        return {
          label: x.index,
          value: x.stocking_id,
          stockingId: x.stocking_id,
          color: x.color,
          stocked: x.name,
          head: x.head,
          weight: x.weight
        };
      });
    },
    FishGroups() {
      const group = this.pond?.growth?.FishGroups ?? [];
      group.sort((a, b) => a.date - b.date);

      return (
        group
          .map((x, index) => {
            const colorIndex = index % fishGroupColors.length;
            const color = fishGroupColors[colorIndex];

            return {
              ...x,
              index: index + 1,
              color
            };
          })
          .reverse() ?? []
      );
    },
    Hatcheries() {
      const hatcheries = cloneObj(store.state.farm.hatcheries);

      return hatcheries
        .filter((x) => !x.archived)
        .map((x) => {
          return {
            label: x.name,
            value: x.fish_id,
            fishId: x.fish_id,
            id: x.id
          };
        });
    },
    History() {
      const history = this.pond?.growth?.ManageHistory ?? [];

      return history.map((x) => {
        const totalHead = x.afterGroupSizes.reduce((acc, curr) => {
          return acc + curr.head;
        }, 0);

        const groupIO = x.groupIO.map((x) => {
          const colorMatch = this.FishGroups.find(
            (group) => group.stocking_id === x.id
          );

          return {
            ...x,
            index: colorMatch.index,
            color: colorMatch.color
          };
        });

        const activeGroups = x.afterGroupSizes.map((x) => {
          const colorMatch = this.FishGroups.find(
            (group) => group.stocking_id === x.id
          );

          return {
            ...x,
            index: colorMatch.index,
            color: colorMatch.color
          };
        });

        const headIn = x.to_account === this.pond.fish_id;

        return {
          ...x,
          headIn,
          totalHead,
          groupIO,
          activeGroups
        };
      });
    },
    PondInfo() {
      const info = {
        numGroups: 0,
        head: 0,
        weight: 0
      };

      for (const group of this.pond.growth.FishGroups) {
        if (group.status !== 'active') {
          continue;
        }

        info.numGroups++;
        info.head += group.head;
        info.weight += group.weight;
      }

      return info;
    },
    PondOptions() {
      const ponds = cloneObj(store.state.farm.ponds);

      return ponds
        .filter((x) => !x.archived && x.id !== this.pond.id)
        .map((x) => {
          return {
            label: x.name,
            value: x.id,
            id: x.id,
            fishId: x.fish_id,
            grainId: x.grain_id
          };
        });
    },
    Processors() {
      const processors = cloneObj(store.state.farm.processors);

      return processors
        .filter((x) => !x.archived)
        .map((x) => {
          return {
            label: x.name,
            value: x.fish_id,
            fishId: x.fish_id,
            grainId: x.grain_id
          };
        });
    },
    RequireWeight() {
      return (
        (this.IOtype === 'Loss' && this.fishMove.date !== this.dateCopyStr) ||
        this.IOtype === 'Harvest' ||
        this.IOtype === 'Stock' ||
        this.IOtype === 'Reconcile' ||
        this.IOtype === 'Transfer'
      );
    }
  },
  watch: {
    pond: {
      deep: true,
      handler() {
        for (const history of this.pond.growth.ManageHistory) {
          if (this.history.fishmove_id === history.fishmove_id) {
            this.history = cloneObj(history);
            break;
          }
        }
      }
    }
  }
};
</script>

<style></style>
